import React from "react";
import PropTypes from "prop-types";
import PaginationDot from "./PaginationDot";

const styles = {
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    gap: "1rem",
  },
};

class Pagination extends React.Component {
  render() {
    const { index, dots } = this.props;
    window.onChangeIndex = this.props.onChangeIndex;
    const children = [];

    for (let i = 0; i < dots; i += 1) {
      children.push(
        <PaginationDot
          key={i}
          index={i}
          active={i === index}
          onClick={() => {
            this.props.onChangeIndex(i);
          }}
        />
      );
    }

    return <div style={styles.root}>{children}</div>;
  }
}

Pagination.propTypes = {
  dots: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  onChangeIndex: PropTypes.func.isRequired,
};

export default Pagination;
