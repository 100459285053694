
const iniatialState = {
    token: ""
};
export default (state = iniatialState, action) => {
    switch (action.type) {
        case 'SET_TOKEN':
            return {
                token: action.payload
            }
        case 'REMOVE_TOKEN': {
            return {
                token: ""
            }
        }
        default:
            return state;
    }
}