import React, { useState } from "react";
import ResultCard from "./ResultCard";
import SwipeableViews from "react-swipeable-views";
import Pagination from "./Pagination";

export default function RemarkCard({ report }) {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleIndex = (index) => {
    setCurrentIndex(index);
  };

  return (
    <div>
      <style
        dangerouslySetInnerHTML={{
          __html: ` .react-swipeable-view-container{
        width: auto;
           }`,
        }}
      />
      <div className="result-cards-title-text">{report.heading}</div>
      <div style={{ padding: "1.5rem 0rem" }}>
        <SwipeableViews
          index={currentIndex}
          onChangeIndex={handleIndex}
          resistance={true}
          // disabled={true}
          enableMouseEvents
        >
          {report.cards.map((card, idx) => (
            <ResultCard
              key={idx}
              heading={card.heading}
              text={card.text}
              image={card.image}
            />
          ))}
        </SwipeableViews>
        <div style={{ padding: "1rem 0" }}>
          <Pagination
            dots={report.cards.length}
            index={currentIndex}
            onChangeIndex={handleIndex}
          />
        </div>
      </div>
    </div>
  );
}
