import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { PolarArea } from "react-chartjs-2";
import {
  getUserLevel,
  levelUrl,
} from "../../../../utils/MindCareInexUtils/getMindcareQuestions";
import { handleSlug, hexToRgbA } from "../../../../utils/_helper";

import "./WeakPointer.css";
import LocalizeText from "../../../common/LocalizeText/LocalizeText";
import { translateText } from "../../../../../../utils/_helper";

const DOWN_IMAGE = "./img/caret-down.svg",
  LOCK_IMAGE = "./img/lock.svg";

const WeakPointer = ({ data }) => {
  const { token } = useSelector((state) => state.SetToken);
  const [levelFailed, setLevelFailed] = useState(false);
  const [levelResponse, setLevelResponse] = useState([]);
  const [display, setDisplay] = useState([]);
  const section1 = data.sections[0];
  const section2 = data.sections[1];
  const bet = Object.keys(section1.matrix);

  useEffect(() => {
    (async () => {
      const response = await getUserLevel(token);
      if (response) {
        setLevelResponse(response);
        setLevelFailed(false);
        const displayData = response.map(() => true);
        setDisplay(displayData);
      } else {
        setLevelFailed(true);
      }
    })();
  }, []);

  const dataSet = {
    labels: ["Behaviour", "Thought", "Emotion"],
    datasets: [
      {
        data: [
          section1.matrix.BEHAVIOUR.score,
          section1.matrix.THOUGHT.score,
          section1.matrix.EMOTION.score,
        ],
        backgroundColor: [
          hexToRgbA(
            section1.matrix.BEHAVIOUR.color,
            section1.matrix.BEHAVIOUR.opacity
          ),
          hexToRgbA(
            section1.matrix.THOUGHT.color,
            section1.matrix.THOUGHT.opacity
          ),
          hexToRgbA(
            section1.matrix.EMOTION.color,
            section1.matrix.EMOTION.opacity
          ),
        ],
        borderWidth: 1,
        hoverBackgroundColor: [
          hexToRgbA(
            section1.matrix.BEHAVIOUR.color,
            section1.matrix.BEHAVIOUR.opacity
          ),
          hexToRgbA(
            section1.matrix.THOUGHT.color,
            section1.matrix.THOUGHT.opacity
          ),
          hexToRgbA(
            section1.matrix.EMOTION.color,
            section1.matrix.EMOTION.opacity
          ),
        ],
      },
    ],
  };
  const options = {
    responsive: true,
    scale: {
      ticks: {
        beginAtZero: true,
        max: 100,
        stepSize: 10,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const handleLevel = () => {
    window.top.postMessage("mciMessage_progress", "*");
  };

  const handleRoute = (task) => {
    if (!task.locked) {
      window.top.postMessage(`mciMessage_${handleSlug(task)}`, "*");
    }
  };

  const TaskInfo = ({ task }) => {
    return (
      <div
        className="mci-level-task"
        style={{
          background: `linear-gradient(to bottom, transparent 50%, rgb(70, 67, 67) 100%), url(${task.image})`,
        }}
        onClick={() => handleRoute(task)}
      >
        <div className="mci-level-task-title">
          <LocalizeText text={task.text} />
        </div>
        {task.locked && (
          <span className="mci-level-task-lock">
            <img src={LOCK_IMAGE} alt="Locked" />
          </span>
        )}
      </div>
    );
  };

  const toggleIndex = (level) => {
    if (level === 0) {
      return;
    }
    const newDisplay = [...display];
    newDisplay[level] = !newDisplay[level];
    setDisplay(newDisplay);
  };

  const LevelInformation = () => {
    return (
      <div className="mci-levels">
        {levelResponse.map((level, levelIndex) => (
          <div key={`level${levelIndex}`} className="mci-level">
            <div
              className="mci-level-title"
              onClick={() => toggleIndex(levelIndex)}
            >
              <div className="mci-level-title-heading">
                {levelIndex + 1}. <LocalizeText text={level.text} />
              </div>
              {level.locked && (
                <img
                  className="mci-level-title-lock"
                  src={LOCK_IMAGE}
                  alt="Locked"
                />
              )}
              <img
                className={`mci-level-title-caret-${display[levelIndex]}`}
                src={DOWN_IMAGE}
                alt="Down"
              />
            </div>
            {display[levelIndex] && (
              <div className="mci-level-tasks">
                {level.tasks.map((task, taskIndex) => (
                  <TaskInfo task={task} />
                ))}
                {levelIndex === 0 && (
                  <button className="mci-level-button" onClick={handleLevel}>
                    <LocalizeText text="View Level" />
                  </button>
                )}
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };

  const [sec1Body, setSec1Body] = useState("");
  const [sec2Body, setSec2Body] = useState("");

  useEffect(async () => {
    if (section1.body) {
      const translatedBody = await translateText(section1.body).catch(
        (err) => section1.body
      );
      setSec1Body(translatedBody);
    }
  }, [section1.body]);
  useEffect(async () => {
    if (section2.body) {
      const translatedBody = await translateText(section2.body).catch(
        (err) => section2.body
      );
      setSec2Body(translatedBody);
    }
  }, [section2.body]);

  return (
    <>
      <div className="weak-pointer-section">
        <div className="weak-section-title">
          <h2>
            <LocalizeText text={section1.heading} />
          </h2>
          <p dangerouslySetInnerHTML={{ __html: sec1Body }}></p>
        </div>
        <div className="weak-pointer-info-section">
          <div className="weak-pointer-info">
            <div className="weak-chart-info">
              <div className="weak-point-details mt-4">
                <div
                  className="weak-chart-color-box"
                  style={{
                    "--fill": `${hexToRgbA(
                      section1.matrix.BEHAVIOUR.color,
                      section1.matrix.BEHAVIOUR.opacity
                    )}`,
                  }}
                ></div>
                <h6 className="weak-chart-text-info">
                  {bet[0].toLowerCase()} - {section1.matrix.BEHAVIOUR.score}%
                </h6>
              </div>

              <div className="weak-point-details mt-4">
                <div
                  className="weak-chart-color-box"
                  style={{
                    "--fill": `${hexToRgbA(
                      section1.matrix.THOUGHT.color,
                      section1.matrix.THOUGHT.opacity
                    )}`,
                  }}
                ></div>
                <h6 className="weak-chart-text-info">
                  {bet[1].toLowerCase()} - {section1.matrix.THOUGHT.score}%
                </h6>
              </div>

              <div className="weak-point-details mt-4">
                <div
                  className="weak-chart-color-box"
                  style={{
                    "--fill": `${hexToRgbA(
                      section1.matrix.EMOTION.color,
                      section1.matrix.EMOTION.opacity
                    )}`,
                  }}
                ></div>
                <h6 className="weak-chart-text-info">
                  {bet[2].toLowerCase()} - {section1.matrix.EMOTION.score}%
                </h6>
              </div>
            </div>
          </div>
          <div className="weak-chart-display">
            <div className="chart-box">
              <PolarArea data={dataSet} options={options} />
            </div>
          </div>
        </div>
        <div className="weak-getting-started">
          <div className="weak-getting-started-head">
            <h2>
              <LocalizeText text={section2.heading.toLowerCase()} />
            </h2>
          </div>
          <p dangerouslySetInnerHTML={{ __html: sec2Body }}></p>
        </div>
        <LevelInformation />
      </div>
    </>
  );
};

export default WeakPointer;
