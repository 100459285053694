import React from 'react'

const FetchLoader = ({ text }) => {
    return (
        <div class="fetch-content">
            <div class="loading">
                <p>{text}</p>
                <span></span>
            </div>
        </div>
    )
}

export default FetchLoader
