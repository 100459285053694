import React, { useEffect } from "react";
import MCIComponent from "../../components/MCIComponent";
import { setToken, removeToken } from "../../store/actions/setToken.action";
import { connect } from "react-redux";
import "./MCIPage.css";

const MCIPage = (props) => {
  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    const token = params.get("aid");
    if (token) props.setUserToken(token);
    else props.removeUserToken();
  }, []);
  return (
    <main>
      <section id="home">
        <div className="mci-page">
          <MCIComponent token={props.token} />
        </div>
      </section>
    </main>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setUserToken: (token) => dispatch(setToken(token)),
  removeUserToken: () => dispatch(removeToken()),
});

export default connect(null, mapDispatchToProps)(MCIPage);
