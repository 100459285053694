
import React, { useEffect } from 'react'
import MatchTherapyFormComponent from '../../components/MatchTherapyForm';
import { setToken, removeToken } from "../../store/actions/setToken.action";
import { connect } from "react-redux";

const MatchTherapyFormPage = (props) => {
    useEffect(() => {
        const params = new URLSearchParams(props.location.search);
        const token = params.get('aid');
        if (token)
            props.setUserToken(token)
        else
            props.removeUserToken();
    }, [])
    return (
        <main>
            <section id="home">
                <div className="container">
                    {/* <img src="/img/logo.png" alt="" className="mci-logo" /> */}
                    <div className="row min-vh-100 py-40">
                    <MatchTherapyFormComponent token={props.token}/>
                    </div>
                </div>
            </section>
        </main>
    )
}

const mapDispatchToProps = (dispatch) => ({
    setUserToken: (token) => dispatch(setToken(token)),
    removeUserToken: () => dispatch(removeToken())
})

export default connect(null, mapDispatchToProps)(MatchTherapyFormPage);
