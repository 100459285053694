import React, { useEffect, useState } from "react";
import { isMobile } from "../../../../utils/_helper";
import "./MentalStrength.css";
import { translateText } from "../../../../../../utils/_helper";
import LocalizeText from "../../../common/LocalizeText/LocalizeText";

const MentalStrength = ({ data }) => {
  const [bodyText, setBodyText] = useState("");

  useEffect(async () => {
    if (data?.body) {
      const translatedBody = await translateText(data.body).catch(
        (err) => data.body
      );
      setBodyText(translatedBody);
    }
  }, [data?.body]);

  return (
    <div className="mental-strength">
      <h2>
        <LocalizeText text={data.heading} />
      </h2>
      <p dangerouslySetInnerHTML={{ __html: bodyText }}></p>
      <div className="report-icon">
        {isMobile() ? (
          <img src={data.imgSrc.mobile} alt="Cognitive_Triad" />
        ) : (
          <img src={data.imgSrc.desktop} alt="Cognitive_Triad" />
        )}
      </div>
    </div>
  );
};

export default MentalStrength;
