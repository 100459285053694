import React from "react";
import { useHistory } from "react-router-dom";
import EventTracker from "../../../utils/EventTracker";

const ConfirmMCIScreen = (props) => {
  const history = useHistory();
  const params = new URLSearchParams(window.location.search);
  const token = params.get("aid");
  const handleClick = () => {
    (async () => {
      EventTracker.instance.trackEvent("MCI Report View", {
        screen: "post_test",
      });
    })();
    let url = window.location.href;
    if (url.includes("v3")) {
      history.push({ pathname: "/reportv3", search: "aid=" + token });
    } else if (url.includes("pre-screener")) {
      history.push({
        pathname: "/reportv2/pre-screener",
        search: "aid=" + token,
      });
    } else if (url.includes("v2")) {
      history.push({ pathname: "/reportv2", search: "aid=" + token });
    }
  };
  return (
    <div className="confirm-screen-box">
      <div className="d-lg-flex align-items-center">
        <img
          src={`${props.imgPath}`}
          className={`${props.applyClass ? "change-img-size" : ""}`}
          alt=""
        />
        <div>
          <p className="main-heading">{props.heading}</p>
          <p className="secondary-heading">{props.subHeading}</p>
          {props.isReportBtnShow && (
            <button
              type="button"
              onClick={handleClick}
              className="btn float-right btn-see-report"
            >
              See Report
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ConfirmMCIScreen;
