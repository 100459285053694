import React, { useState, useEffect } from 'react';
import MatchFormPage from './sub_components/matchTherapy/MatchFormPage';
import ConfirmScreen from './sub_components/matchTherapy/ConfirmMCIScreen';
import { connect } from 'react-redux';
import FetchLoader from './common/Loader/FetchLoader';

const MatchTherapyFormComponent = props => {
  const [pageNo, changepageNo] = useState(0);
  const [loader, setLoader] = useState(false);
  const [invalid, setInvalid] = useState(false);

  useEffect(() => {
    if (!props.token.token) {
      console.log(props.token.token);
      setLoader(true);
    } else {
      setLoader(false);
    }
  }, [props.token.token]);
  
  setTimeout(() => {
    if (!props.token.token) {
      setLoader(false);
      setInvalid(true);
    }
  }, 2000);

  return (
    <>
      {loader ? (
        <FetchLoader />
      ) : props.token.token ? (
        <>
          {pageNo === 0 && (
            <MatchFormPage
              showStarNowScreen={() => changepageNo(0)}
              showConfirmScreen={() => changepageNo(1)}
            />
          )}
          {pageNo === 1 && (
            <ConfirmScreen
              heading="Thank You for taking the test!"
              isReportBtnShow={true}
              imgPath="/img/check.svg"
            />
          )}
        </>
      ) : !invalid ? (
        <FetchLoader />
      ) : (
        <ConfirmScreen
          imgPath="/img/invalidUrl.gif"
          heading="Invalid URL !"
          subHeading="Your client has issued a malformed request"
          applyClass={true}
        />
      )}
    </>
  );
};

const mapStateToProps = state => ({
  token: state.SetToken,
});

export default connect(mapStateToProps)(MatchTherapyFormComponent);
