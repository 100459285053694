import React from "react";
import StartNow from "./sub_components/MCIReport/StartNow";

const MCIReportComponent = ({ setPageNo, pageNo }) => {
  return (
    <>
      {/* 1st Page */}
      {pageNo === 0 && <StartNow setPageNo={setPageNo} pageNo={pageNo} />}
    </>
  );
};

export default MCIReportComponent;
