import mixpanel from 'mixpanel-browser';

const trackMixPanelEvent = (type, properties) => {
    if (properties) {
        mixpanel.app.track(type, properties)
    } else {
        mixpanel.app.track(type);
    }
}

export default class EventTracker {

    static instance = EventTracker.instance || new EventTracker();

    constructor() {
        this.token = process.env.REACT_APP_MIXPANEL_TOKEN;
        mixpanel.init(this.token, { debug: process.env.REACT_APP_NODE_ENV === "staging" ? true : false }, "app");
    }

    trackEvent(type, properties = null) {
        return trackMixPanelEvent(type, properties);
    }
}