import { useDispatch } from "react-redux";
import store from "../store/index";

export const query = {
  responseHandler: (resp, type, isAuth = false, stats = false) => {
    if (resp.error !== null) {
      if (
        resp.status === 200 &&
        resp.error.errors &&
        resp.error.errors[0].code === "USER_NOT_ATHORIZED"
      ) {
       
      }
      return {
        success: false,
        data: (resp.error.errors && resp.error.errors[0]) || {},
        status: resp.status,
      };
    } else {
      let statKeys = stats ? Object.keys(resp.data) : [],
        statistics = {};
      if (isAuth) statKeys = statKeys.filter((v) => v !== "auth");
      if (statKeys.length > 0) {
        let keys = ["total", "limit", "offset"];
        keys.map((key) => {
          let found = statKeys.find((v) => v.toLowerCase().includes(key));
          if (found) statistics[key] = resp.data[found];
        });
      }
      return {
        success: true,
        data: !!isAuth ? resp.data.auth[type] : resp.data[type],
        statistics,
        status: resp.status,
      };
    }
  },
};

export const mutation = {
  responseHandler: (resp, fetchKey, type, isAuth = false) => {
    const HandleLogOut = () => {
      store.dispatch({
        type: "SET_ACCESS_DETAIL",
        payload: { accessCodeDetail: {} },
      });
      window.location.href = "/";
    };
    if (resp.error !== null) {
      if (
        resp.status === 200 &&
        resp.error.errors &&
        resp.error.errors[0].code === "USER_NOT_ATHORIZED"
      ) {
      
      }
      return {
        success: false,
        data: (resp.error.errors && resp.error.errors[0]) || {},
        status: resp.status,
      };
    } else {
      return {
        success: true,
        data: !!isAuth
          ? resp.data.authMutation[type][fetchKey]
          : resp.data[type],
        status: resp.status,
      };
    }
  },
};
