import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import EventTracker from "../../../utils/EventTracker";
import {
  getAllQuestions,
  getMCIUser,
  saveQuestionResult,
} from "../../../utils/MindCareInexUtils/getMindcareQuestions";
import LocalizeText from "../../common/LocalizeText/LocalizeText";

const MatchFormPage = (props) => {
  const loadingMessage = "Loading Next Questions ......";

  const [loaded, changeLoaded] = useState(false);
  const [loadingMsg, changeLoadingMsg] = useState(false);
  const [questionNo, changeQuestionNo] = useState(0);
  const [RadioBtnVal, changeRadioBtnVal] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [error, changeError] = useState("");
  const [pointer, changePointer] = useState(-1);
  const [categories, changeCatoegories] = useState([]);
  const [staticQuestionLength, setStaticQuestionLength] = useState(0);
  const [loader, setLoader] = useState(false);

  let additionalQuestion = [];
  let additionalQuestionOptions = [];

  useEffect(() => {
    (async () => {
      EventTracker.instance.trackEvent("MCI Started");
    })();
  }, []);

  const intialQuestionsResults = [
    { questionId: "What is your gender?", answer: "" },
    { questionId: "What age bracket do you fall in?", answer: "" },
  ];

  const b2cInitialQuestionResult = [
    { questionId: "What best describe your role?", answer: "" },
    { questionId: "What functional role is your expertise in?", answer: "" },
    {
      questionId:
        "How long have you been working with your current organisation?",
      answer: "",
    },
  ];

  const b2bInitialQuestionResult = [
    {
      questionId: "What best describes your role at the organisation?",
      answer: "",
    },
    { questionId: "What function/department do you work for?", answer: "" },
    { questionId: "What is the Team size?", answer: "" },
    {
      questionId:
        "How long have you been working with your current organisation?",
      answer: "",
    },
  ];

  const [questionResults, changeQuestionResults] = useState(
    intialQuestionsResults
  );

  const intialQuestionsAndOptions = [
    {
      question: "What is your gender?",
      options: [
        { optionid: "Male", optionName: "Male" },
        { optionid: "Female", optionName: "Female" },
        { optionid: "Other", optionName: "Other" },
      ],
    },
    {
      question: "What age bracket do you fall in?",
      options: [
        { optionid: "18-30 Years", optionName: "18-30 Years" },
        { optionid: "31-54 Years", optionName: "31-54 Years" },
        { optionid: "55 Years & above", optionName: "55 Years & above" },
      ],
    },
  ];

  const b2cInitialQuestionsAndOptions = [
    {
      question: "What best describe your role?",
      options: [
        { optionid: "Student", optionName: "Student" },
        { optionid: "Intern", optionName: "Intern" },
        { optionid: "Freelancer", optionName: "Freelancer" },
        { optionid: "Full-time Employee", optionName: "Full-time Employee" },
        { optionid: "Homemaker", optionName: "Homemaker" },
        { optionid: "Other", optionName: "Other" },
      ],
    },

    {
      question: "What functional role is your expertise in?",
      options: [
        { optionid: "IT/Tech", optionName: "IT/Tech" },
        { optionid: "Marketing or Sales", optionName: "Marketing or Sales" },
        { optionid: "Manufacturing", optionName: "Manufacturing" },
        { optionid: "HR", optionName: "HR" },
        { optionid: "Accounting/Finance", optionName: "Accounting/Finance" },
        { optionid: "Customer Service", optionName: "Customer Service" },
        { optionid: "Product", optionName: "Product" },
        { optionid: "Other", optionName: "Other" },
      ],
    },

    {
      question:
        "How long have you been working with your current organisation?",
      options: [
        { optionid: "less than 1 year", optionName: "less than 1 year" },
        { optionid: "1-5 years", optionName: "1-5 years" },
        { optionid: "More than 5 years", optionName: "More than 5 years" },
      ],
    },
  ];

  const b2bInitialQuestionsAndOptions = [
    {
      question: "What best describes your role at the organisation?",
      options: [
        { optionid: "Intern", optionName: "Intern" },
        { optionid: "Full-time Employee", optionName: "Full-time Employee" },
        { optionid: "HR", optionName: "HR" },
        { optionid: "Manager", optionName: "Manager" },
        { optionid: "Leader", optionName: "Leader" },
        { optionid: "Other", optionName: "Other" },
      ],
    },

    {
      question: "What functional role is your expertise in?",
      options: [
        {
          optionid: "Research and Development",
          optionName: "Research and Development",
        },
        { optionid: "Marketing", optionName: "Marketing" },
        { optionid: "Manufacturing", optionName: "Manufacturing" },
        { optionid: "Sales", optionName: "Sales" },
        { optionid: "Accounting/Finance", optionName: "Accounting/Finance" },
        { optionid: "Customer Service", optionName: "Customer Service" },
        { optionid: "Product", optionName: "Product" },
        { optionid: "IT/Tech", optionName: "IT/Tech" },
        { optionid: "Other", optionName: "Other" },
      ],
    },

    {
      question: "What is the Team size?",
      options: [
        { optionid: "Less than 50", optionName: "Less than 50" },
        { optionid: "Less than 100", optionName: "Less than 100" },
        { optionid: "Less than 500", optionName: "Less than 500" },
        { optionid: "Above 1000", optionName: "Above 1000" },
      ],
    },

    {
      question:
        "How long have you been working with your current organisation?",
      options: [
        { optionid: "less than 1 year", optionName: "less than 1 year" },
        { optionid: "1-5 years", optionName: "1-5 years" },
        { optionid: "More than 5 years", optionName: "More than 5 years" },
        { optionid: "NA", optionName: "NA" },
      ],
    },
  ];

  const [questionsAndOptions, changeQuestionsAndOptions] = useState(
    intialQuestionsAndOptions
  );

  const clickRadioBtn = (option) => {
    changeRadioBtnVal(option);
    const questionval = questionResults;
    questionval[questionNo]["answer"] = option;
    changeQuestionResults([...questionval]);
  };

  const optionSelected = (option) => {
    setSelectedOption(option);
    const questionval = questionResults;
    questionval[questionNo]["answer"] = option;
    changeQuestionResults([...questionval]);
  };

  useEffect(async () => {
    const mciUser = await getMCIUser(props.token.token);
    const { questionCategories, questions, results } = await getAllQuestions(
      props.token.token
    );

    additionalQuestion =
      mciUser?.user && !!mciUser?.user.b2c
        ? b2cInitialQuestionResult
        : b2bInitialQuestionResult;

    additionalQuestionOptions =
      mciUser?.user && !!mciUser?.user.b2c
        ? b2cInitialQuestionsAndOptions
        : b2bInitialQuestionsAndOptions;

    setStaticQuestionLength(additionalQuestion.length);
    changeQuestionsAndOptions([
      ...questionsAndOptions,
      ...additionalQuestionOptions,
      ...questions,
    ]);
    changeLoaded(true);
    changeCatoegories([...categories, ...questionCategories]);
    changeQuestionResults([
      ...questionResults,
      ...additionalQuestion,
      ...results,
    ]);
  }, []);

  useEffect(() => {
    if (questionResults[questionNo] && questionResults[questionNo]["answer"])
      clickRadioBtn(questionResults[questionNo]["answer"]);
    changeClock();
  }, [questionNo]);

  const changeClock = () => {
    if (questionNo - (staticQuestionLength + 2) < 0) {
      changePointer(-1);
    } else {
      for (var i = 0; i < categories.length; i++) {
        if (
          questionNo - (staticQuestionLength + 2) <
          categories[i].cumlativeCount
        ) {
          changePointer(i);
          break;
        }
      }
    }
  };
  const handleBack = () => {
    if (questionNo === 0) return;
    else {
      changeQuestionNo(questionNo - 1);
    }
  };

  const handleNext = () => {
    if (!questionResults[questionNo]["answer"]) {
      changeError("Please Answer Required Question *");
    } else {
      changeClock();
      if (!loaded && questionNo === staticQuestionLength + 1) {
        changeLoadingMsg(loadingMessage);
      } else {
        if (questionNo === questionsAndOptions.length - 1) {
          (async () => {
            EventTracker.instance.trackEvent("MCI Completed");
          })();
          setLoader(true);
          saveQuestionResult(
            questionResults,
            props.token.token,
            staticQuestionLength + 2
          ).then((res) => {
            if (res && res.ResponseCode === "200") {
              props.showConfirmScreen();
              changeError("");
            } else {
              changeError("Couldn't save Test ... Try again");
            }
          });
          setTimeout(() => setLoader(false), 700);
        } else {
          (async () => {
            const properties = {
              "Question Name": questionsAndOptions[questionNo]["question"],
            };
            EventTracker.instance.trackEvent(
              "MCI Question Answered",
              properties
            );
          })();
          changeQuestionNo(questionNo + 1);
        }
      }
      changeError("");
    }
  };
  useEffect(() => {
    if (loaded && loadingMsg === loadingMessage) {
      changeLoadingMsg("");
      handleNext();
    }
  }, [loaded]);

  return (
    <>
      <div className="col">
        {pointer >= 0 && (
          <div className="mci-question-status">
            <div className="step-title mr-2">
              <h3 className="current-step-title">
                <LocalizeText text={categories[pointer]["categoryQuestions"]} />
              </h3>
              {pointer < categories.length - 1 && (
                <p className="next-step-name">
                  <LocalizeText
                    text={`Up Next : ${
                      categories[pointer + 1]["categoryQuestions"]
                    }`}
                  />
                </p>
              )}
            </div>
            <div className="step-circle">
              <div className="step-count">
                {pointer === 0 ? (
                  <>
                    {" "}
                    <span>
                      {questionNo - (staticQuestionLength + 1)}
                    </span> Of <span>{categories[pointer]["questions"]}</span>
                  </>
                ) : (
                  <>
                    <span>
                      {questionNo -
                        staticQuestionLength -
                        categories[pointer - 1]["cumlativeCount"] -
                        1}
                    </span>{" "}
                    Of <span>{categories[pointer]["questions"]}</span>
                  </>
                )}
              </div>
            </div>
          </div>
        )}
        {/* Questions Screens Related to Tabs */}
        <div className="mci-question-list">
          <h3>
            <LocalizeText text={questionsAndOptions[questionNo]["question"]} />
          </h3>
        </div>
        <div className={`mci-questions`}>
          {questionsAndOptions[questionNo]["options"].map((option) => (
            <div key={option.optionid}>
              <button
                key={option.optionid}
                onClick={() => {
                  setSelectedOption(option.optionid);
                  optionSelected(option.optionid);
                  if (questionNo === questionsAndOptions.length - 1) {
                    return;
                  } else {
                    setTimeout(() => handleNext(), 700);
                  }
                }}
                className={`btn mci-next-question mci-questions-buttons ${
                  selectedOption === option.optionid ? " selected" : ""
                }`}
              >
                <LocalizeText text={option.optionName} />
              </button>
            </div>
          ))}
        </div>
        {error && (
          <div className="error-message">
            <p className="text-danger mt-3">
              <LocalizeText text={error} />
            </p>
          </div>
        )}
        {loadingMsg && (
          <p className="loading-msg">
            <LocalizeText text={loadingMsg} />
          </p>
        )}
        <div className="mci-questions-proceed">
          {questionNo === 0 ? null : (
            <button onClick={handleBack}>
              <LocalizeText text="Previous" />
            </button>
          )}
          {loaded && questionNo === questionsAndOptions.length - 1 ? (
            !loader ? (
              <button type="submit" onClick={handleNext}>
                <LocalizeText text="Submit Test" />
              </button>
            ) : (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <button className="boat-btn cursor-pointer register-boat">
                <span
                  className="spinner-grow spinner-grow-sm mr-2 cornFlower-color"
                  role="status"
                  aria-hidden="true"
                ></span>
                <LocalizeText text="Loading..." />
              </button>
            )
          ) : (
            <button
              type="submit"
              className="btn btn-mci-question-proceed"
              onClick={handleNext}
            >
              <LocalizeText text="Next" />
            </button>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  token: state.SetToken,
});

export default connect(mapStateToProps)(MatchFormPage);
