import React from "react";
import LocalizeText from "../../../v1/src/components/common/LocalizeText/LocalizeText";

export default function DarkModeButton({
  label = "",
  onClick,
  style,
  innerStyle,
}) {
  return (
    <div style={style}>
      <div className="cta-button-main" onClick={onClick}>
        <div
          className="cta-button-inner"
          style={{ padding: `0 1rem`, ...innerStyle }}
        >
          <LocalizeText text={label} />
        </div>
      </div>
    </div>
  );
}
