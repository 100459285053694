import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import MCIService from "../../../api/services/MCIService";
import DarkModeButton from "../../common/Buttons/DarkModeButton";
import "./MCIBeginJourneyScreen.css";
import { isMobile } from "../../../utils/_helper";
import LocalizeText from "../../../v1/src/components/common/LocalizeText/LocalizeText";

const MCIBeginJourneyScreen = (props) => {
  const [showTransition, setShowTransition] = useState(false);
  const [mciConfigData, setMciConfigData] = useState([]);
  const history = useHistory();

  useEffect(() => {
    (async () => {
      let response = await MCIService.getMCIConfig();
      if (response.success) {
        setMciConfigData(response.data.mci);
      }
    })();
  }, []);

  const handleClick = () => {
    setShowTransition(true);

    // get started event
    window.top.postMessage(`mciV2event_getStarted`, "*");
    if (window?.flutter_inappwebview)
      window.flutter_inappwebview.callHandler("ANALYTIC_EVENT", {
        "Begin your journey clicked": "",
      });

    setTimeout(() => {
      props.startJourney();
    }, 500);
  };

  const handleClose = () => {
    window.top.postMessage(`mciV0event_closeMci`, "*");
    if (window?.flutter_inappwebview)
      window.flutter_inappwebview.callHandler("mciV0event_closeMci", "*");
  };

  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `
        @media(max-width: 320px){
          .mci-page{
          width: 100%;
          height: auto;
          background: #212121;
          }
          .onboarding-info{
            gap: 1rem !important;
          }
      }
      `,
        }}
      />
      <div className="onboarding-background">
        <div className="onboarding-container">
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              padding: "8px",
              position: "absolute",
              top: "0.4em",
              right: "1.5em",
            }}
          >
            <img
              onClick={() => handleClose()}
              src={
                isMobile()
                  ? "https://cdn.mindpeers.co/dark-mode/site-icons/new-dashboard-icons/cross.svg"
                  : "https://cdn.mindpeers.co/site-icons/new-dashboard-icons/cross.svg"
              }
              alt=""
            />
          </div>
          <div
            style={{
              gap: "0rem",
              justifyContent: isMobile() ? "flex-start" : "center",
            }}
            className="onboarding-info"
          >
            <div className="onboarding-img">
              <img
                src={
                  isMobile()
                    ? "/img/mci/mci.png"
                    : "/img/mci/mountain-latest.svg"
                }
                alt="NEW USER"
              />
            </div>
            {!isMobile() && (
              <div className="onboarding-text">
                <div className="onboarding-text-section">
                  <LocalizeText text={mciConfigData?.intro_screen?.heading} />
                </div>
                <div
                  style={{
                    fontSize: "1rem",
                    color: isMobile() && `var(--secondary-text-color)`,
                    fontFamily: isMobile() && `var(--primary-sub-heading-font)`,
                  }}
                >
                  <LocalizeText
                    text={mciConfigData?.intro_screen?.sub_heading}
                  />
                </div>
              </div>
            )}
            <div className="test-button">
              {!isMobile() ? (
                <div className="begin-journey-cta" onClick={handleClick}>
                  <LocalizeText text={mciConfigData?.intro_screen?.cta?.text} />
                </div>
              ) : (
                <DarkModeButton
                  label={mciConfigData?.intro_screen?.cta?.text}
                  style={{
                    width: `80%`,
                    maxWidth: "max-content",
                    padding: "0 14px",
                  }}
                  onClick={handleClick}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {showTransition && <div className="transition-body"></div>}
    </>
  );
};

export default MCIBeginJourneyScreen;
