import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import MCIReportComponent from "../../components/MCIReportComponent";
import ConfirmMCIScreen from "../../components/sub_components/matchTherapy/ConfirmMCIScreen";
import { setToken, removeToken } from "../../store/actions/setToken.action";
import LocalizeText from "../../components/common/LocalizeText/LocalizeText";

const MCIOldReport = (props) => {
  const dispatch = useDispatch();
  const [pageNo, setPageNo] = useState(0);
  const params = new URLSearchParams(props.location.search);
  const token = params.get("aid");

  useEffect(() => {
    if (token) dispatch(setToken(token));
    else dispatch(removeToken());
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pageNo]);

  return (
    <main>
      {token ? (
        <section id={pageNo === 0 ? "report" : "report-background"}>
          <div className="container">
            {/* <img src="/img/logo.png" alt="" className="mci-logo" /> */}
            <div className="row min-vh-100 py-5">
              <MCIReportComponent setPageNo={setPageNo} pageNo={pageNo} />
            </div>
          </div>
          <section className="mci-footer">
            <div className="mci-footer-content">
              <p>
                <LocalizeText
                  text={`Copyright MindPeers 2021 - Report cannot be replicated.
                Recipient abides by the`}
                />{" "}
                <Link
                  to={{ pathname: "https://mindpeers.co/terms-and-condition" }}
                  target="_blank"
                >
                  {" "}
                  <LocalizeText text="Terms & Conditions" />{" "}
                </Link>{" "}
                <LocalizeText text="and" />{" "}
                <Link
                  to={{ pathname: "https://mindpeers.co/privacy-policy" }}
                  target="_blank"
                >
                  <LocalizeText text="Company Privacy Policy" />
                </Link>
                <LocalizeText text=". Read" />{" "}
                <Link
                  to={{ pathname: "https://mindpeers.co/disclaimer" }}
                  target="_blank"
                >
                  <LocalizeText text="Disclaimer and Limitation of Liability" />
                </Link>
                .
              </p>
            </div>
          </section>
        </section>
      ) : (
        <ConfirmMCIScreen
          imgPath="/img/invalidUrl.gif"
          heading="Invalid URL !"
          subHeading="Your client has issued a malformed request"
          applyClass={true}
        />
      )}
    </main>
  );
};

export default MCIOldReport;
