import React, { useEffect, useState } from "react";
import "./DialougeCard.css";
import LocalizeText from "../../../common/LocalizeText/LocalizeText";
import { translateText } from "../../../../../../utils/_helper";

const DialogueCard = ({ card, next, prev, refer }) => {
  const [cardText, setCardText] = useState("");
  const [cardBody, setCardBody] = useState("");

  useEffect(async () => {
    if (card.iText) {
      const translatedCard = await translateText(card.iText).catch(
        (err) => card.iText
      );
      setCardText(translatedCard);
    }
  }, [card.iText]);

  useEffect(async () => {
    if (card.body) {
      const translatedBody = await translateText(card.body).catch(
        (err) => card.body
      );
      setCardBody(translatedBody);
    }
  }, [card.body]);

  return (
    <div ref={refer} className="dialogue">
      <div className="mouse-point" onClick={() => prev()}>
        <img src="/img/left-arrow.svg" alt="PREVIOUS" />
      </div>
      <div className="dialouge-card-box">
        <div className="d-flex box">
          <h3 className="dialouge-card-box-heading">
            <LocalizeText text={card.heading} />
          </h3>
          <div className="dialouge-card-box-tooltip-wrapper">
            <img
              style={{ verticalAlign: "middle" }}
              src="/img/info-tooltip.svg"
              alt=""
              title=""
            />
            <div className="dialouge-card-box-tooltip">
              <p dangerouslySetInnerHTML={{ __html: cardText }}></p>
            </div>
          </div>
        </div>

        <p className="dialouge-card-box-body">
          <p dangerouslySetInnerHTML={{ __html: cardBody }}></p>
        </p>
      </div>
      <div className="mouse-point" onClick={() => next()}>
        <img src="/img/right-arrow.svg" alt="NEXT" />
      </div>
    </div>
  );
};

export default DialogueCard;
