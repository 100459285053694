import React, { useState, useEffect } from "react";
import MCIQuestionsList from "./sub_components/MCIQuestionnaire/MCIQuestionsList";
import ConfirmScreen from "./sub_components/MCIQuestionnaire/ConfirmMCIScreen";
import { connect } from "react-redux";
import FetchLoader from "./common/Loader/FetchLoader";
import MCIBeginJourneyScreen from "./sub_components/MCIQuestionnaire/MCIBeginJourneyScreen";
import { useHistory } from "react-router-dom";
import SessionService from "../api/services/SessionService";

const MCIComponent = (props) => {
  const [pageNo, changepageNo] = useState(0);
  const [loader, setLoader] = useState(false);
  const [invalid, setInvalid] = useState(false);

  const history = useHistory();
  const params = new URLSearchParams(window.location.search);
  const token = params.get("aid");
  const ctaStatus = params.get("cta_status");

  useEffect(() => {
    if (!props.token.token) {
      setLoader(true);
    } else {
      setLoader(false);
    }
  }, [props.token.token]);

  setTimeout(() => {
    if (!props.token.token) {
      setLoader(false);
      setInvalid(true);
    }
  }, 2000);

  const openResultPage = () => {
    let url = window.location.href;
    if (url.includes("v3")) {
      history.push({
        pathname: "/reportv3",
        search: "aid=" + token + "&cta_status=" + ctaStatus,
        state: { testStatus: "submitted" },
      });
    } else if (url.includes("pre-screener")) {
      history.push({
        pathname: "/reportv2/pre-screener",
        search: "aid=" + token,
        state: { testStatus: "submitted" },
      });
    } else if (url.includes("v2")) {
      history.push({
        pathname: "/reportv2",
        search: "aid=" + token + "&cta_status=" + ctaStatus,
        state: { testStatus: "submitted" },
      });
    }
  };

  return (
    <>
      {loader ? (
        <FetchLoader />
      ) : props.token.token ? (
        <>
          {pageNo === 0 && (
            <MCIBeginJourneyScreen startJourney={() => changepageNo(1)} />
          )}
          {pageNo === 1 && (
            <MCIQuestionsList
              showStarNowScreen={() => changepageNo(1)}
              showConfirmScreen={() => changepageNo(2)}
            />
          )}
          {pageNo === 2 && openResultPage()}
        </>
      ) : !invalid ? (
        <FetchLoader />
      ) : (
        <ConfirmScreen
          imgPath="/img/invalidUrl.gif"
          heading="Invalid URL !"
          subHeading="Your client has issued a malformed request"
          applyClass={true}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  token: state.SetToken,
});

export default connect(mapStateToProps)(MCIComponent);
