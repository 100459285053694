import React from "react";
import { Modal } from "react-bootstrap";

import "./RemarkSheet.css";
import LocalizeText from "../../../v1/src/components/common/LocalizeText/LocalizeText";

const RemarkSheet = ({
  showRemarkSheet,
  setShowRemarkSheet,
  iTagInfo,
  setITagInfo,
}) => {
  const hideRemarkSheet = () => {
    setShowRemarkSheet(false);
    setITagInfo("");
  };
  return (
    <>
      <Modal
        className="remark-sheet-modal modal-open-up"
        show={showRemarkSheet}
        onHide={() => hideRemarkSheet()}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        scrollable={false}
      >
        <Modal.Header>
          <div className="remark-sheet-modal-header">
            <LocalizeText text={iTagInfo.heading} />
          </div>
        </Modal.Header>
        <Modal.Body className="remark-sheet-body-modal">
          <div className="remark-description">
            <LocalizeText text={iTagInfo.text} />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default RemarkSheet;
