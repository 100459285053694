import React, { useState, useEffect } from "react";
import { ProgressBar } from "react-bootstrap";
import { useSelector } from "react-redux";
import MCIService from "../../../api/services/MCIService";
import { ToastConstant } from "../../../utils/toastConstant";
import CustomToast from "../../common/Toast/CustomToast";
import "./DemographicQuestions.css";
import { Select, Space } from "antd";
import CommonService from "../../../api/services/CommonService";
import LocalizeText from "../../../v1/src/components/common/LocalizeText/LocalizeText";

export default function DemographicQuestions({
  setShowResultLoader,
  demographicQuestions,
}) {
  const [questionNo, setQuestionNo] = useState(0);
  const [selectedOption, setSelectedOption] = useState("");
  const [mciStartTime, setMciStartTime] = useState(new Date().toISOString());
  const [currentTime, setCurrentTime] = useState(new Date().toISOString());
  const [questionResults, setQuestionResults] = useState([]);
  const [rollNumberValue, setRollNumberValue] = useState("");
  const [mciCurrentId, setMciCurrentId] = useState("");
  const [disableBtn, setDisableBtn] = useState(true);
  const { mciId, abc } = useSelector((state) => state.commonState);
  const [open, setOpen] = useState(false);
  const [toastData, setToastData] = useState({
    time: 0,
    severity: "",
    message: "",
  });
  useEffect(() => {
    if (mciId) {
      setMciCurrentId(mciId);
    }
  }, [mciId]);

  const handleNext = () => {
    setQuestionNo(questionNo + 1);
  };

  const handleOptionSelected = (question, option) => {
    console.log(option, "option");
    setCurrentTime(new Date().toISOString());
    setSelectedOption(option.value);
    const questionval = questionResults;
    questionval.push({
      question: question.id,
      type: question.type,
      fieldType: question.field_type,
      option: option,
      start: currentTime,
      end: new Date().toISOString(),
    });
    setQuestionResults([...questionval]);
    if (questionNo === demographicQuestions.length - 1) {
      setTimeout(() => submitMCI(questionval), 200);
      return;
    } else {
      setTimeout(() => handleNext(), 200);
    }
  };
  const submitPreScreener = (question) => {
    if (
      (Object.keys(orgDetails).length > 0 &&
        orgDetails?.accessCode.toLowerCase() !== "in-uacs" &&
        orgDetails?.accessCode.toLowerCase() !== "in-uako" &&
        rollNumberValue.trim().length >= 1) ||
      rollNumberValue.trim().length >= 7
    ) {
      setCurrentTime(new Date().toISOString());
      setDisableBtn(false);
      // setSelectedOption(option.value);
      const questionval = questionResults;
      questionval.push({
        question: question.id,
        type: question.type,
        fieldType: question.field_type,
        option: {
          value: rollNumberValue,
        },
        start: currentTime,
        end: new Date().toISOString(),
      });
      // setQuestionResults([...questionval]);
      if (questionNo === demographicQuestions.length - 1) {
        setTimeout(() => submitMCI(questionval), 200);
        return;
      } else {
        setTimeout(() => handleNext(), 200);
      }
    }
  };

  const submitMCI = (questionval) => {
    (async () => {
      let response = await MCIService.submitMCI(
        mciId,
        mciStartTime,
        new Date().toISOString(),
        questionval
      );
      if (response.success) {
        setQuestionResults([...questionval]);

        setShowResultLoader(true);
        setTimeout(() => setShowResultLoader(false), 200);
      } else {
        setQuestionResults([]);
        setOpen(true);
        setToastData(
          ToastConstant.DYNAMIC_FAIL(response.data.message, 3000, "error")
        );
      }
    })();
  };

  const [orgDetails, setOrgDetails] = useState([]);

  useEffect(() => {
    (async () => {
      let response = await CommonService.getUser();
      if (response.success) {
        setOrgDetails(response.data.organisation);
      } else {
        setOpen(true);
        setToastData(
          ToastConstant.DYNAMIC_FAIL(response.data.message, 3000, "error")
        );
      }
    })();
  }, []);

  useEffect(() => {
    if (
      (Object.keys(orgDetails).length > 0 &&
        orgDetails?.accessCode.toLowerCase() !== "in-uacs" &&
        orgDetails?.accessCode.toLowerCase() !== "in-uako") ||
      rollNumberValue.trim().length >= 7
    ) {
      setDisableBtn(false);
    } else {
      setDisableBtn(true);
    }
  }, [rollNumberValue, orgDetails]);

  const handleChange = (event) => {
    const result = event.target.value.replace(/[^\w\s]/gi, "").toUpperCase();
    setRollNumberValue(result);
  };

  const [value, setValue] = useState([]);
  const changeHandler = (newValue) => {
    // helps to set a selected value to dropdown
    setValue(newValue);
  };

  const selectProps = {
    mode: "single",
    style: {
      width: "100%",
    },
    maxTagCount: "responsive",
  };

  return (
    <div className="col">
      <style
        dangerouslySetInnerHTML={{
          __html: ` 
          .progress {
            margin: 0 -30px;
          }
          .progress-bar{
            background-color: #BBCEFF;
           }
           .ant-select-arrow{
            background: url(../img/drop-down-ui.svg);
            height: 38px !important;
            width: 38px !important;
            display: inline-block;
            background-position: 6px 5px;
            background-repeat: no-repeat;
          }
          .ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
            height: 100%;
            align-items: center;
            background: #212121;
            color: #cfcfcf;
            border: none !important;
          }
          .ant-select-item-option-content, .ant-select-dropdown, .ant-select-item-option-selected:not(.ant-select-item-option-disabled){
            background: #212121;
            color: #cfcfcf !important;
          }          
           
           `,
        }}
      />
      <div className="mci-container">
        {demographicQuestions && (
          <div className="mci-questions-wrapper demographic-wrapper">
            <ProgressBar now={100} />
            <div className="questions-wrapper">
              <div className="demographics-heading">
                <LocalizeText
                  text={demographicQuestions[questionNo]?.heading}
                />
              </div>
              <div className="mci-questions-container">
                <LocalizeText
                  text={demographicQuestions[questionNo]?.question}
                />
              </div>
              <div className={`mci-questions-listing`}>
                {demographicQuestions[questionNo]?.kind === "MULTI_CHOICE" &&
                  demographicQuestions[questionNo]?.options?.map(
                    (option, idx) => (
                      <div
                        key={idx}
                        onClick={() => {
                          handleOptionSelected(
                            demographicQuestions[questionNo],
                            option
                          );
                        }}
                        className={`mci-next-question mci-questions-btn ${
                          selectedOption === option.value ? " selected" : ""
                        }`}
                      >
                        <LocalizeText text={option.name} />
                      </div>
                    )
                  )}
                {demographicQuestions[questionNo]?.kind === "INPUT" && (
                  <input
                    onInput={(e) => {
                      if (e.target.value.length > e.target.maxLength)
                        e.target.value = e.target.value.slice(
                          0,
                          e.target.maxLength
                        );
                    }}
                    className="pre-screener-input"
                    type="text"
                    required
                    maxLength={7}
                    placeholder="Enter Here"
                    onChange={handleChange}
                    value={rollNumberValue}
                  />
                )}
                {demographicQuestions[questionNo]?.kind === "DROP_DOWN" && (
                  <select
                    className="custom-select-box"
                    value={demographicQuestions[questionNo]?.options[0].value}
                    onChange={(event) =>
                      handleOptionSelected(demographicQuestions[questionNo], {
                        name: event.target.value,
                        value: event.target.value,
                      })
                    }
                  >
                    {demographicQuestions[questionNo]?.options?.map(
                      (option) => (
                        <option key={option.key} value={option.value}>
                          <LocalizeText text={option.name} />
                        </option>
                      )
                    )}
                  </select>
                )}
              </div>
              {demographicQuestions[questionNo]?.kind === "INPUT" && (
                <div
                  style={{
                    bottom: "10%",
                    position: "absolute",
                  }}
                  className="test-button demographic-cta"
                >
                  <div
                    onClick={() => {
                      submitPreScreener(demographicQuestions[questionNo]);
                    }}
                    className={`begin-journey-cta ${
                      disableBtn ? "disable-demographic-btn" : ""
                    }`}
                  >
                    <LocalizeText text="Submit" />
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      {open && toastData.severity && toastData.time && (
        <CustomToast
          time={toastData.time}
          severity={toastData.severity}
          open={open}
          setOpen={setOpen}
          message={toastData.message}
        />
      )}
    </div>
  );
}
