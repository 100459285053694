import mixpanel from 'mixpanel-browser';

export const anchorRedirection = {
    tag: (anchorId) => {
        let a = document.createElement('a');
        a.href = `#${anchorId}`;
        a.click();
    }

}
export const format = {
    fileName: (name) => {
        console.log(name);
        const dots = "...";
        const limit = 10;
        let fileNameFormat;
        if (name.length > limit) {
            const type = name.split('.').pop();
            fileNameFormat = name.substring(0, limit) + dots + type;

        }
        else {
            return name;
        }
        return fileNameFormat;
    }
}

export const facebookHelper = {
    leadOperation: () => {
        return window.fbq('track', 'Lead')
    }
}

export const hexToRgbA = (hex, opacity) => {
    let c;
    if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
        c= hex.substring(1).split('');
        if(c.length=== 3){
            c= [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c= '0x'+c.join('');
        return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+`,${opacity})`;
    }
    throw new Error('Bad Hex');
}

export const handleSlug = (card) => {
    let url =
      card.type === 'GUIDE'
        ? `guides`
        : card.type === 'EMOTION'
        ? `emotions`
        : card.type === 'JOURNAL'
        ? `journal`
        : card.type === 'BOAT'
        ? `boat`
        : card.type === 'SESSION'
        ? `therapy`
        : '';
    if (url && card.slug) {
      return `${url}/${card.slug}`;
    }
    return url;
  };

  export const isMobile = () => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    )
    {
      return true
    }
  }