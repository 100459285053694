import React from "react";
import { useState } from "react";
import SwipeableViews from "react-swipeable-views";
import Pagination from "./Pagination";

import "./RecommendationCard.css";
import SuggestionCard from "./SuggestionCard";

const RecommendationCard = ({ recommendationList, report }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const handleIndex = (index) => {
    setCurrentIndex(index);
  };
  return (
    <div>
      <style
        dangerouslySetInnerHTML={{
          __html: ` .react-swipeable-view-container{
    width: auto;
       }`,
        }}
      />
      <div className="result-cards-title-text">{report.heading}</div>

      <div style={{ padding: "1.5rem 0rem" }}>
        {window.location.pathname.split("/").includes("pre-screener") ? (
          <div
            style={{ display: "flex", flexDirection: "column", gap: "2rem" }}
          >
            {report.cards.map((card, idx) => (
              <SuggestionCard
                key={idx}
                heading={card.heading}
                text={card.text}
                image={card.image}
                slug={card.deeplink.screen}
              />
            ))}
          </div>
        ) : (
          <SwipeableViews
            index={currentIndex}
            onChangeIndex={handleIndex}
            resistance={true}
            // disabled={true}
            enableMouseEvents
          >
            {report.cards.map((card, idx) => (
              <SuggestionCard
                key={idx}
                heading={card.heading}
                text={card.text}
                image={card.image}
                slug={card.deeplink.screen}
              />
            ))}
          </SwipeableViews>
        )}
        {report.type === "SUGGESTION" && (
          <div style={{ padding: "1rem 0" }}>
            <Pagination
              dots={report.cards.length}
              index={currentIndex}
              onChangeIndex={handleIndex}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default RecommendationCard;
