import React, { useState } from "react";
import { ProgressBar, Carousel, Collapse } from "react-bootstrap";
import "./StartNow.css";
import { useEffect } from "react";
import MCIService from "../../../api/services/MCIService";
import DemographicQuestions from "./DemographicQuestions";
import RemarkCard from "./RemarkCard";
import RecommendationCard from "./RecommendationCard";
import { useHistory } from "react-router-dom";
import EventTracker from "../../../utils/EventTracker";
import RemarkSheet from "./RemarkSheet";
import { isMobile } from "../../../utils/_helper";
import GradientButton from "../../common/Buttons/GradientButton/GradientButton";
import LocalizeText from "../../../v1/src/components/common/LocalizeText/LocalizeText";

const StartNow = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showResultLoader, setShowResultLoader] = useState(true);
  const [mciResult, setMciResult] = useState([]);
  const [mciResultCards, setMciResultCards] = useState([]);
  const [mciLoadingTime, setMciLoadingTime] = useState("");
  const [historyState, setHistoryState] = useState(null);
  const history = useHistory();
  const params = new URLSearchParams(window.location.search);
  const token = params.get("aid");
  const ctaStatus = params.get("cta_status");

  const handleIndex = (index) => {
    setCurrentIndex(index);
  };

  useEffect(() => {
    (async () => {
      let response = await MCIService.getMCIConfig();
      if (response.success) {
        setMciLoadingTime(response.data.mci.loading_time);
      }
    })();
  }, []);

  useEffect(() => {
    if (history.location.state && history.location.state.testStatus) {
      setHistoryState(history.location.state.testStatus);
    }
  }, []);

  useEffect(() => {
    if (mciLoadingTime) {
      (async () => {
        let response = await MCIService.getMCIResult();
        if (response.success) {
          const url = window.location.href;
          setMciResult(response.data);
          setMciResultCards(response.data.cards);
          history.push({
            pathname: url.includes("pre-screener")
              ? "/reportv2/pre-screener"
              : url.includes("v3")
              ? "/reportv3"
              : "/reportv2",
            search: "aid=" + token + "&cta_status=" + ctaStatus,
            state: { testStatus: null },
          });
          if (response.data.ready) {
            (async () => {
              EventTracker.instance.trackEvent("MCI Report View");
            })();
          }
        }
      })();
    }
  }, [mciLoadingTime, showResultLoader]);

  useEffect(() => {
    if (mciLoadingTime && historyState) {
      setTimeout(() => {
        setShowResultLoader(false);
      }, mciLoadingTime);
    } else {
      setTimeout(() => {
        setShowResultLoader(false);
      }, 1500);
    }
  }, [mciLoadingTime]);

  const handleClose = () => {
    window.top.postMessage(`mciV0event_closeMci`, "*");
    if (window?.flutter_inappwebview)
      window.flutter_inappwebview.callHandler("mciV0event_closeMci", "*");
  };

  const [iTagInfo, setITagInfo] = useState("");
  const [showRemarkSheet, setShowRemarkSheet] = useState(false);

  const handleInfo = (cardInfo) => {
    if (cardInfo.text && cardInfo.text.length) {
      setShowRemarkSheet(true);
      setITagInfo(cardInfo);
    }
  };

  const handleUserInsights = () => {
    window.top.postMessage(`mciV1event_userInsights`, "*");
    if (window?.flutter_inappwebview)
      window.flutter_inappwebview.callHandler("mciV1event_userInsights", "*");
  };

  const [openStates, setOpenStates] = useState([]);

  const toggleOpenState = (idx) => {
    const newOpenStates = [...openStates];
    newOpenStates[idx] = !newOpenStates[idx];
    setOpenStates(newOpenStates);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          minHeight: "100vh",
        }}
      >
        {showResultLoader ? (
          <>
            {historyState ? (
              <div className="result-loader">
                <img
                  style={{ height: "30rem", width: "100%" }}
                  src={
                    isMobile() ? "/img/mci/mci-white.gif" : "/img/mci/mci.gif"
                  }
                  alt=""
                />
              </div>
            ) : (
              <div id="cover-spin"></div>
            )}
          </>
        ) : (
          <>
            {showRemarkSheet && (
              <RemarkSheet
                showRemarkSheet={showRemarkSheet}
                setShowRemarkSheet={setShowRemarkSheet}
                iTagInfo={iTagInfo}
                setITagInfo={setITagInfo}
              />
            )}
            {mciResult.ready ? (
              <div className="result-screen">
                <div style={{ marginLeft: "-1rem" }}>
                  <img
                    onClick={() => handleClose()}
                    src={
                      isMobile()
                        ? "https://cdn.mindpeers.co/dark-mode/site-icons/new-dashboard-icons/cross.svg"
                        : "https://cdn.mindpeers.co/site-icons/new-dashboard-icons/cross.svg"
                    }
                    alt=""
                  />
                </div>
                {mciResult.report.map((report, idx) =>
                  report.type === "SCORE" &&
                  !window.location.pathname
                    .split("/")
                    .includes("pre-screener") ? (
                    <div key={idx} className="result-screen-head">
                      <div className="result-screen-text">
                        <LocalizeText text={report.heading} />
                      </div>
                      {isMobile()
                        ? report.cards.map((card, id) => (
                            <>
                              <div
                                onClick={() => toggleOpenState(id)}
                                key={id}
                                className="all-report-wrapper"
                              >
                                <div
                                  className="report-container"
                                  // onClick={() => handleInfo(card)}
                                >
                                  <img src={card.image} alt="strength" />
                                  <div className="report-strength">
                                    <div className="report-card-heading">
                                      <div style={{ flex: 1 }}>
                                        <LocalizeText text={card.heading} />{" "}
                                      </div>
                                      {/* {card.text && card.text.length && (
                                        <img
                                          style={{
                                            verticalAlign: "middle",
                                            marginLeft: "-0.3rem",
                                            // marginBottom: "0.5rem",
                                            // width: "5%"
                                          }}
                                          src="/img/i-button.svg"
                                          alt=""
                                          title=""
                                        />
                                      )} */}
                                      <div
                                        style={{
                                          fontFamily: `var(--primary-heading-font)`,
                                          color: "#B2FFDA",
                                        }}
                                      >
                                        {card.score}%
                                      </div>
                                    </div>
                                    <div className="progress-score-wrapper">
                                      <ProgressBar
                                        className="report-progress"
                                        now={card.score}
                                        variant={card.color}
                                      />
                                    </div>
                                  </div>
                                  <img
                                    style={{
                                      transform:
                                        openStates[id] && "rotate(180deg)",
                                    }}
                                    src="/img/mci/arrow-down.png"
                                    alt="arrow"
                                  />
                                </div>
                                <Collapse in={openStates[id]}>
                                  <div className="result-card-message">
                                    <LocalizeText text={card?.text} />
                                  </div>
                                </Collapse>
                              </div>
                            </>
                          ))
                        : report.cards.map((card, id) => (
                            <>
                              <div key={id} className="all-report-wrapper">
                                <div
                                  className="report-container"
                                  onClick={() => handleInfo(card)}
                                >
                                  <img src={card.image} />
                                  <div className="report-strength">
                                    <div className="report-card-heading">
                                      <LocalizeText text={card.heading} />{" "}
                                      {card.text && card.text.length && (
                                        <img
                                          style={{
                                            verticalAlign: "middle",
                                            marginLeft: "-0.3rem",
                                            // marginBottom: "0.5rem",
                                            // width: "5%"
                                          }}
                                          src="/img/i-button.svg"
                                          alt=""
                                          title=""
                                        />
                                      )}
                                    </div>
                                    <div className="progress-score-wrapper">
                                      <ProgressBar
                                        className="report-progress"
                                        now={card.score}
                                        variant={card.color}
                                      />
                                      <div
                                        style={{
                                          fontFamily: `var(--primary-heading-font)`,
                                        }}
                                      >
                                        {card.score}%
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ))}
                    </div>
                  ) : (
                    <>
                      {report.type === "SUGGESTION" ||
                      report.type === "SUGGESTION_VERTICAL" ? (
                        <RecommendationCard report={report} />
                      ) : (
                        <>
                          {report.type === "REMARK" && (
                            <RemarkCard key={idx} report={report} />
                          )}
                        </>
                      )}
                    </>
                  )
                )}
                {mciResult.type !== "PRE_SCREENER" &&
                  isMobile() &&
                  (ctaStatus === "INACTIVE" ? null : (
                    <div
                      style={{
                        position: "fixed",
                        bottom: 0,
                        left: 0,
                        right: 0,
                        backgroundColor: `var(--body-dark)`,
                        padding: `1rem 2rem`,
                      }}
                    >
                      <GradientButton
                        text="Analyze your result"
                        onClick={handleUserInsights}
                      />
                    </div>
                  ))}
              </div>
            ) : (
              <DemographicQuestions
                setShowResultLoader={setShowResultLoader}
                demographicQuestions={mciResult.questions}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default StartNow;
