import React, { useEffect, useState } from "react";
import "./About.css";
import LocalizeText from "../../../common/LocalizeText/LocalizeText";
import { translateText } from "../../../../../../utils/_helper";

const About = ({ data }) => {
  const [bodyText, setBodyText] = useState("");

  useEffect(async () => {
    if (data?.body) {
      const translatedBody = await translateText(data.body).catch(
        (err) => data.body
      );
      setBodyText(translatedBody);
    }
  }, [data?.body]);

  return (
    <div className="about-section">
      <h2>
        <LocalizeText text={data.heading} />
      </h2>
      <p dangerouslySetInnerHTML={{ __html: bodyText }}></p>
    </div>
  );
};

export default About;
