import React from "react";
import "./GradientButton.css";
import LocalizeText from "../../../../v1/src/components/common/LocalizeText/LocalizeText";

const GradientButton = ({ text, onClick, style }) => {
  return (
    <div className="gradient-button-root" onClick={onClick} style={style}>
      <div className="gradient-button-label">
        <LocalizeText text={text} />
      </div>
    </div>
  );
};

export default GradientButton;
