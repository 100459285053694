import React, { useEffect, useMemo, useState } from "react";
import { translateText } from "../../../../../utils/_helper";

const LocalizeText = ({
  text,
  children,
  isName = false,
  noTranslate = false,
}) => {
  const targetLanguage = localStorage.getItem("lang") ?? "en";
  const [translatedText, setTranslatedText] = useState("");
  const handleTranslate = async () => {
    if ((text && typeof text == "string") || children) {
      if (noTranslate) setTranslatedText(children ? children : text);
      else {
        try {
          const res = await translateText(text, targetLanguage);
          setTranslatedText(res);
        } catch (e) {
          console.log(e);
          setTranslatedText(children ? children : text);
        }
      }
    }
  };

  useEffect(() => {
    handleTranslate();
  }, [targetLanguage, text, children]);

  return translatedText ?? "";
};

export default React.memo(LocalizeText);
