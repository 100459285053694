import React, { useEffect, useState } from "react";
import "./MCIBeginJourneyScreen.css";
import { isMobile, platformType } from "../../../utils/_helper";

const MCIUpdateAppScreen = () => {
  const [type, setType] = useState("");

  useEffect(() => {
    setType(platformType());
  }, []);

  let iOsStore = "https://apple.co/3aos2Uc",
    playStore = "https://bit.ly/3yNT9Bi";

  const handleClick = () => {
    if (type === "IOS") {
      window.location.href = iOsStore;
    } else if (type === "ANDROID") {
      window.location.href = playStore;
    }
  };

  return (
    <>
      <div className="onboarding-background">
        <div className="onboarding-container">
          <div className="onboarding-info">
            <div className="onboarding-img">
              <img src={isMobile() ? "/img/mci/mountain-latest-dark.svg" : "img/mci/mountain-latest.svg"} alt="NEW USER" />
            </div>
            <div className="onboarding-text">
              <div className="onboarding-text-section">
                To use new mci please update the app
              </div>
            </div>
            <div className="test-button">
              <div className="begin-journey-cta" onClick={() => handleClick()}>
                Update app
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MCIUpdateAppScreen;
