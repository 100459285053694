import React from "react";
import "./SuggestionCard.css";

const SuggestionCard = ({ heading, text, image, slug }) => {
  const handleRedirection = (slug) => {
    window.top.postMessage(`recommendation_${slug}`, "*");
  };
  return (
    <div
      className="suggestion-card-container"
      onClick={() => handleRedirection(slug)}
    >
      <div className="suggestion-card-image-section">
        <img src={image} alt={heading} />
      </div>
      <div className="suggestion-card-info-section">
        <div className="suggestion-card-title">{heading}</div>
        <div className="suggestion-card-message">{text}</div>
      </div>
    </div>
  );
};

export default SuggestionCard;
