import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getUserTestReport } from "../../../utils/MindCareInexUtils/getMindcareQuestions";
import ConfirmMCIScreen from "../matchTherapy/ConfirmMCIScreen";
import About from "./ReportPages/About";
import MentalStrength from "./ReportPages/MentalStrength";
import WhatMakesYou from "./ReportPages/WhatMakesYou";
import ScorePage from "./ReportPages/ScorePage";
import BrainSelector from "./ReportPages/BrainSelector";
import WeakPointer from "./ReportPages/WeakPointer";
// import EightPage from './ReportPages/EightPage';
// import EleventhPage from './ReportPages/EleventhPage';
// import FifthPage from './ReportPages/FifthPage';
// import NinethPage from './ReportPages/NinethPage';
// import SeventhPage from './ReportPages/SeventhPage';
// import SixthPage from './ReportPages/SixthPage';
// import TenthPage from './ReportPages/TenthPage';
import FetchLoader from "../../common/Loader/FetchLoader";
import LocalizeText from "../../common/LocalizeText/LocalizeText";

const ReportPages = ({ setPageNo, pageNo }) => {
  const history = useHistory();
  const { token } = useSelector((state) => state.SetToken);
  const [reportResp, setReportResp] = useState([]);
  const [levelResponse, setLevelResponse] = useState([]);
  const [cardName, setCardName] = useState("");
  const [resultFailed, setResultFailed] = useState(false);
  const [levelFailed, setLevelFailed] = useState(false);

  const handleNext = () => {
    setPageNo(pageNo + 1);
    // history.push(`/report/${pageNo + 1}`)
  };

  const handleBack = () => {
    setPageNo(pageNo - 1);
    // if ((pageNo - 1) !== 0) {
    //     history.push(`/report/${pageNo - 1}`)
    // } else {
    //     history.push(`/report`)
    // }
  };

  useEffect(() => {
    if (
      reportResp?.reportsDetail &&
      reportResp?.reportsDetail[1]?.cards?.length
    ) {
      console.log(reportResp);
      const activeCard = reportResp.reportsDetail[1].cards.filter(
        (el) => el.isActive === true
      )[0]?.heading;
      setCardName(activeCard);
    }
  }, [reportResp]);

  useEffect(() => {
    (async () => {
      const resp = await getUserTestReport(token);
      if (resp) {
        setReportResp(resp);
        setResultFailed(false);
      } else {
        setResultFailed(true);
      }
    })();
  }, []);

  const handleClose = () => {
    window.close(reportResp);
  };

  return (
    <>
      {pageNo <= 6 && (
        <>
          <div className=" col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="mci-upper-bar">
              <div className="prev-step" onClick={() => handleBack()}>
                <i className="fas fa-chevron-left mr-2"></i>
                <LocalizeText text="Back" />
              </div>
            </div>
          </div>
          {reportResp?.reportsDetail?.length ? (
            <>
              {pageNo === 1 ? (
                <About data={reportResp.reportsDetail[0]} />
              ) : pageNo === 2 ? (
                <MentalStrength data={reportResp.reportsDetail[1]} />
              ) : pageNo === 3 ? (
                <WhatMakesYou data={reportResp.reportsDetail[2]} />
              ) : pageNo === 4 ? (
                <ScorePage
                  data={reportResp.reportsDetail[3]}
                  cardName={cardName}
                />
              ) : pageNo === 5 ? (
                <BrainSelector
                  data={reportResp.reportsDetail[4]}
                  cardName={cardName}
                />
              ) : (
                pageNo === 6 && (
                  <WeakPointer data={reportResp.reportsDetail[5]} />
                )
              )}
              {pageNo === 6 ? null : (
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="report-btn float-right clearfix">
                    <button
                      type="button"
                      onClick={() => handleNext()}
                      className="btn"
                    >
                      <LocalizeText text="Next" />
                    </button>
                  </div>
                </div>
              )}
            </>
          ) : resultFailed || levelFailed ? (
            <ConfirmMCIScreen
              imgPath="/img/invalidUrl.gif"
              heading="Something Went Wrong!"
              subHeading="Your client has issued a malformed request, please call help and support."
              applyClass={true}
            />
          ) : (
            <FetchLoader />
          )}
        </>
      )}
    </>
  );
};

export default ReportPages;
