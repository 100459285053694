import React from "react";
import { Route, Switch } from "react-router-dom";
import MCIUpdateAppScreen from "../components/sub_components/MCIQuestionnaire/MCIUpdateAppScreen";
import MCIPage from "../pages/MCI/MCIPage";
import MCIReport from "../pages/MCIReport/MCIReport";
import MatchTherapyFormPage from "../v1/src/pages/matchTherapyForm/MatchTherapyFormPage";
import MCIOldReport from "../v1/src/pages/MCIReport/MCIOldReport";

const PublicRoute = () => {
  return (
    <Switch>
      <Route exact path="/v2" component={MCIPage} />
      <Route exact path="/v2/pre-screener" component={MCIPage} />
      <Route exact path="/reportv2" component={MCIReport} />
      <Route exact path="/reportv2/pre-screener" component={MCIReport} />
      <Route exact path="/v3" component={MCIPage} />
      <Route exact path="/reportv3" component={MCIReport} />
      <Route exact path="/update-app-required" component={MCIUpdateAppScreen} />
      <Route exact path="/v1" component={MatchTherapyFormPage} />
      <Route exact path="/reportv1" component={MCIOldReport} />
    </Switch>
  );
};

export default PublicRoute;
