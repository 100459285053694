import React from "react";
import EventTracker from "../../../utils/EventTracker";
import "./StartNow.css";
import LocalizeText from "../../common/LocalizeText/LocalizeText";

const StartNow = ({ setPageNo, pageNo }) => {
  const handlePage = () => {
    (async () => {
      EventTracker.instance.trackEvent("See full report");
    })();
    setPageNo(pageNo + 1);
  };

  return (
    <>
      <div className="start-now">
        <div className="start-now-head">
          <div className="start-now-text">
            <h2>
              <LocalizeText text="MindPeers MindCare" /> <sup>&trade;</sup>{" "}
              <LocalizeText text="Report" />
            </h2>
            <h6>
              <LocalizeText text="A comprehensive snippet into your mental strength." />
            </h6>
          </div>
          <div className="start-now-report-btn float-left clearfix">
            <button type="button" onClick={() => handlePage()} className="btn">
              <LocalizeText text="See full report" />
            </button>
          </div>
        </div>
        <div className="start-now-image">
          <img
            src="https://cdn.mindpeers.co/site-icons/thought_emotion_behaviour.png"
            alt=""
          />
        </div>
      </div>
    </>
  );
};

export default StartNow;
