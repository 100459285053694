import React, { useEffect, useRef, useState } from "react";
import domtoimage from "dom-to-image";
import { saveAs } from "file-saver";
import { isMobile } from "../../../../utils/_helper";
import DialogueCard from "./DialogueCard";
import FivePoints from "./FivePoints";
import "./WhatMakesYou.css";
import LocalizeText from "../../../common/LocalizeText/LocalizeText";
import { translateText } from "../../../../../../utils/_helper";

const WhatMakesYou = ({
  data: { screenshot = false, heading = "", body = "", cards = [] },
}) => {
  const [index, setIndex] = useState(0);

  const dialogueRef = useRef(null);

  const [bodyText, setBodyText] = useState("");

  useEffect(async () => {
    if (body) {
      const translatedBody = await translateText(body).catch((err) => body);
      setBodyText(translatedBody);
    }
  }, [body]);

  const update = (newSelectedIndex) => {
    setIndex(newSelectedIndex);
    if (dialogueRef.current !== null && isMobile()) {
      dialogueRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const takeScreenShot = () => {
    domtoimage
      .toJpeg(document.getElementById("what-makes-you"), {
        quality: 1,
        bgcolor: "#fff",
      })
      .then(function (dataUrl) {
        var link = document.createElement("a");
        link.download = "what-makes-you.jpeg";
        link.href = dataUrl;
        link.click();
      });
  };

  const checkUserDevice = isMobile() && (
    <div className="screenshot float-right">
      <button onClick={takeScreenShot}>
        <img src="/img/share-btn.svg" alt="" />
      </button>
    </div>
  );

  return (
    <>
      {screenshot && (
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          {checkUserDevice}
        </div>
      )}

      <div
        className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
        id="what-makes-you"
      >
        <div className="what-makes-you">
          <h2>
            <LocalizeText text={heading} />
          </h2>
          <p dangerouslySetInnerHTML={{ __html: bodyText }}></p>
        </div>

        <div className="what-makes-you-bottom">
          <div className="d-grid align-items-center flex-xl-nowrap flex-lg-nowrap flex-md-wrap flex-sm-wrap flex-wrap grid-new-col-2 position-relative justify-content-between">
            <DialogueCard
              refer={dialogueRef}
              card={cards[index]}
              next={() => setIndex(index === cards.length - 1 ? 0 : index + 1)}
              prev={() => setIndex(index === 0 ? cards.length - 1 : index - 1)}
            />
            <div className="box-select">
              <FivePoints index={index} update={update} cards={cards} />
              <div className="ellipse"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default WhatMakesYou;
