import React, { useEffect } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import PropTypes from "prop-types";
import LocalizeText from "../../../v1/src/components/common/LocalizeText/LocalizeText";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const CustomToast = (props) => {
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    props.setOpen(false);
  };

  useEffect(() => {
    props.setOpen(props.open);
  }, [props.open]);

  return (
    <Snackbar
      open={props.open}
      autoHideDuration={props.time}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={props.severity}>
        <LocalizeText text={props.message} />
      </Alert>
    </Snackbar>
  );
};

CustomToast.propTypes = {
  time: PropTypes.number.isRequired,
  severity: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default CustomToast;
