import { mutation, query } from "../../utils/responseHandler";
import api from "./ApiService";

const params = new URLSearchParams(window.location.search);
const token = params.get("aid");
export default class MCIService {
  static async getMCIConfig() {
    let response = await api(
      `query {
          auth(token: "${token}") {
            getConfigV2 (type: MCI) {
                mci{
                  loader_assets{
                    image
                    text
                  }
                  loading_time
                  intro_screen{
                    image
                    heading
                    sub_heading
                    cta{
                      text
                      deeplink{
                        screen
                        paramOne
                        paramTwo
                      }
                    }
                  }
                }
              }
          }
        }`
    );
    return query.responseHandler(response, "getConfigV2", true);
  }

  static async listMCIQuestions(type) {
    let response = await api(
      `query {
            auth(token: "${token}") {
              listMciQuestions(type: ${type}) {
                id
                questions{
                  id
                  type
                  kind
                  field_type
                  heading
                  question
                  background{
                    type
                    image
                  }
                  options{
                    id
                    name
                    value
                    order
                  }
                }
              }
            }
          }`
    );
    return query.responseHandler(response, "listMciQuestions", true);
  }

  static async getMCIResult() {
    let response = await api(
      `query {
            auth(token: "${token}") {
              getMciResult{
                ready
                type
                questions{
                  id
                  type
                  kind
                  field_type
                  heading
                  question
                  background{
                    type
                    image
                  }
                  options{
                    id
                    name
                    value
                    order
                  }
                }
                report{
                  type
                  heading
                  cards{
                    card_type
                    heading
                    image
                    color
                    background
                    text
                    score
                    deeplink {
                      screen
                      paramOne
                      paramTwo
                    }
                  }
                }
              }
            }
          }`
    );
    return query.responseHandler(response, "getMciResult", true);
  }

  static async submitMCI(id, start, end, mciTestResponses) {
    let response = await api(
      `mutation submitMCI($mciTestResponses:[mciTestResponseInput]!) {
        authMutation(token: "${token}") {
          update{
            submitMCI(id:"${id}",start:"${start}",end:"${end}",mciTestResponses:$mciTestResponses)
          }
        }
      }`,
      { mciTestResponses }
    );
    return mutation.responseHandler(response, "submitMCI", "update", true);
  }
}
