import React from "react";
import "./ResultCard.css";
import { isMobile } from "../../../utils/_helper";
import LocalizeText from "../../../v1/src/components/common/LocalizeText/LocalizeText";

export default function ResultCard({ heading, text, image }) {
  return (
    <div className="result-card-container">
      {isMobile() ? (
        <div className="result-card-title">
          <div style={{ marginRight: `0.5rem` }}>
            <img src={image} width={25} height={25} />
          </div>
          {heading}
        </div>
      ) : (
        <div className="result-card-title">
          <LocalizeText text={heading} />
        </div>
      )}
      <div className="result-card-message">
        <LocalizeText text={text} />
      </div>
    </div>
  );
}
