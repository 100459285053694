import LocalizeText from "../../../common/LocalizeText/LocalizeText";

const FivePoints = ({ cards, index, update }) => {
  const handleClick = (itemIndex) => {
    update(itemIndex);
  };

  const RECTANGLES = [
      {
        width: 125,
        height: 79,
        transform: "translate(279 521)",
        score: { x: "53", y: "35" },
        text: { x: "26", y: "60" },
        heading: cards[0].heading,
        userScore: cards[0].score,
      },
      {
        width: 111,
        height: 82,
        transform: "translate(201 367)",
        score: { x: "50", y: "35" },
        text: { x: "28", y: "60" },
        heading: cards[1].heading,
        userScore: cards[1].score,
      },
      {
        width: 125,
        height: 87,
        transform: "translate(141 579)",
        score: { x: "53", y: "35" },
        text: { x: "30", y: "60" },
        heading: cards[2].heading,
        userScore: cards[2].score,
      },
      {
        width: 152,
        height: 79,
        transform: "translate(449 605)",
        score: { x: "65", y: "35" },
        text: { x: "27", y: "60" },
        heading: cards[3].heading,
        userScore: cards[3].score,
      },
      {
        width: 225,
        height: 86,
        transform: "translate(396 419)",
        score: { x: "100", y: "35" },
        text: { x: "25", y: "60" },
        heading: cards[4].heading,
        userScore: cards[4].score,
      },
    ],
    ACTIVE = "#a1a1f2",
    INACTIVE = "#e3e3ff";

  return (
    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 480 317">
        <g data-name="Group 14205" transform="translate(-141 -367)">
          {RECTANGLES.map((item, itemIndex) => {
            const {
              width,
              height,
              transform,
              score,
              text,
              userScore,
              heading,
            } = item;
            return (
              <g
                key={heading}
                onClick={() => handleClick(itemIndex)}
                transform={transform}
              >
                <rect
                  width={width}
                  height={height}
                  rx="21"
                  fill={index === itemIndex ? ACTIVE : INACTIVE}
                  className="mouse-point"
                />
                {index !== itemIndex && (
                  <>
                    <circle cx="18" cy="18" r="5" fill={ACTIVE}></circle>
                    <circle
                      className="circle-effect"
                      r="18"
                      cx="18"
                      cy="18"
                      transform={item}
                    >
                      <animate
                        attributeType="xml"
                        attributeName="r"
                        from="8"
                        to="14"
                        dur="1s"
                        repeatCount="indefinite"
                      />
                    </circle>
                  </>
                )}
                <text
                  x={score.x}
                  y={score.y}
                  fontSize="24"
                  font-family="AvenirNext-Bold"
                  className="mouse-point"
                  fill="#000"
                >
                  {userScore}
                </text>
                <text
                  x={text.x}
                  y={text.y}
                  fontSize="15"
                  fill="#000"
                  font-family="AvenirNext-Bold"
                  className="mouse-point"
                >
                  <LocalizeText text={heading} />
                </text>
              </g>
            );
          })}
        </g>
      </svg>
    </div>
  );
};

export default FivePoints;
