import axios from "axios";
import { mutation, query } from "../../utils/responseHandler";
import api from "./ApiService";

const params = new URLSearchParams(window.location.search);

export default class CommonService {
  static async getUser() {
    const token = params.get("aid");
    let offset = new Date().getTimezoneOffset();
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (!token) return false;
    let response = await api(
      `query {
            auth(token: "${token}") {
              getUser(offset: ${offset}, timezone: "${timezone}"){
                organisation{
                  accessCode
                }
              }
            }
        }`
    );
    return query.responseHandler(response, "getUser", true);
  }

  static async translate(text, to = "en", from = "en") {
    if (to == from) return text;
    const data = {
      text: text,
      to: to,
      from: from,
    };
    let response = await axios.post(process.env.REACT_APP_TRANSLATE_URL, data);
    return response.data;
  }
}
