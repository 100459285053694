import React from "react";
import LocalizeText from "../LocalizeText/LocalizeText";

const FetchLoader = ({ text }) => {
  return (
    <div class="fetch-content">
      <div class="loading">
        <p>
          <LocalizeText text={text} />
        </p>
        <span></span>
      </div>
    </div>
  );
};

export default FetchLoader;
