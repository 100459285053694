import { combineReducers } from "redux";
import TopBarDisplay from "./topBarDisplay.reducer";
import SetToken from "./setToken.reducer";
import commonState from "./commonReducer";

export default combineReducers({
  TopBarDisplay,
  SetToken,
  commonState,
});
