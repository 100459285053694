import React, { Fragment, useState, useEffect } from "react";
import PublicRoute from "./routers/PublicRoute";
import "./Variables.css";
import "./App.css";

function App() {
  const params = new URLSearchParams(window.location.search);
  const language = params.get("lang"); // 'en' language code required

  useEffect(() => {
    if (language) {
      localStorage.setItem("lang", language);
    }
  }, [language]);

  // versioning code
  let [appVersion, setVersion] = useState(
    window.localStorage.getItem("version")
      ? Number(window.localStorage.getItem("version"))
      : 0
  );

  // const [modalHidden, setModalBool] = useState(true);

  const updateVersion = (version) => {
    window.localStorage.setItem("version", version + "");
    console.log("Clearing cache and hard reloading...");
    if (caches) {
      caches.keys().then(function (names) {
        for (let name of names) {
          caches.delete(name);
        }
      });
    }
    window.location.reload(true);
  };

  const getVersionJSON = (mounted) => {
    fetch("/app-version.json")
      .then((response) => {
        if (!response.ok) {
          console.error("Error while getting app-version.json");
        }
        return response.json();
      })
      .then((json) => {
        if (mounted) {
          updateVersion(json.version);
        }
        if (Number(window.localStorage.getItem("version")) < json.version) {
          // setModalBool(false);
          updateVersion(json.version);
        }
        setVersion(json.version);
      })
      .catch((err) => {
        console.error("Error while getting app-version.json");
      });
  };
  const checkVersion = () => {
    if (!Number(window.localStorage.getItem("version"))) getVersionJSON(true);
    else getVersionJSON();
    setInterval(() => {
      getVersionJSON();
    }, 1800000);
    // interval set to a hour
  };

  useEffect(() => {
    checkVersion();
  }, []);

  return (
    <Fragment>
      <PublicRoute />
    </Fragment>
  );
}

export default App;
