import React from "react";
import { isMobile } from "../../../../utils/_helper";
import "./HealthyPointer.css";
import LocalizeText from "../../../common/LocalizeText/LocalizeText";
const HealthyPointer = ({ data }) => {
  const points = data.sections[2];
  const factor = points.factors.map((factor) => {
    return (
      <div className="strong-reason">
        {isMobile() ? (
          <img src={factor.imgSrc.mobile} alt="" />
        ) : (
          <img src={factor.imgSrc.desktop} alt="" />
        )}
        <p>
          <LocalizeText text={factor.text} />
        </p>
      </div>
    );
  });

  return (
    <div className="healthy-pointer">
      <h2>
        <LocalizeText text={points.heading} />
      </h2>
      <div>
        <LocalizeText text={factor} />
      </div>
    </div>
  );
};

export default HealthyPointer;
