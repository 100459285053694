import React from "react";
import PropTypes from "prop-types";

const styles = {
  root: {
    cursor: "pointer",
    border: "1px solid #CFD6D6",
    borderRadius: "100%",
    background: "none",
    backgroundColor: "#CFD6D6",
    height: 10,
    width: 10,
    padding: 0,
  },
  active: {
    backgroundColor: "#16A9B1",
  },
};

class PaginationDot extends React.Component {
  render() {
    const { active } = this.props;

    let styleDot = {
      ...styles.root,
    };

    if (active) {
      styleDot = { ...styles.root, ...styles.active };
    }

    return (
      <button
        type="button"
        style={styleDot}
        onClick={() => {
          this.props.onClick();
        }}
      ></button>
    );
  }
}

PaginationDot.propTypes = {
  active: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default PaginationDot;
