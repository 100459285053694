import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import MCIReportComponent from "../../components/MCIReportComponent";
import ConfirmMCIScreen from "../../components/sub_components/MCIQuestionnaire/ConfirmMCIScreen";
import { setToken, removeToken } from "../../store/actions/setToken.action";

const MCIReport = (props) => {
  const dispatch = useDispatch();
  const [pageNo, setPageNo] = useState(0);
  const params = new URLSearchParams(props.location.search);
  const token = params.get("aid");

  useEffect(() => {
    if (token) dispatch(setToken(token));
    else dispatch(removeToken());
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pageNo]);

  return (
    <main>
      {token ? (
        <section id={pageNo === 0 ? "report" : "report-background"}>
          <div className="container">
            {/* <img src="/img/logo.png" alt="" className="mci-logo" /> */}
            <div>
              <MCIReportComponent setPageNo={setPageNo} pageNo={pageNo} />
            </div>
          </div>
        </section>
      ) : (
        <ConfirmMCIScreen
          imgPath="/img/invalidUrl.gif"
          heading="Invalid URL !"
          subHeading="Your client has issued a malformed request"
          applyClass={true}
        />
      )}
    </main>
  );
};

export default MCIReport;
