const commonState = {
  mciId: "",
};

export default (state = commonState, action) => {
  switch (action.type) {
    case "SET_MCI_ID":
      return {
        ...state,
        mciId: action.payload,
      };

    default:
      return {
        ...state,
      };
  }
};
