
const iniatialState = {
    openTopBar: false
};
export default (state = iniatialState, action) => {
    switch (action.type) {
        case 'SHOW_ONLY_TOP_BAR':
            return {
                openTopBar: true
            }
        case 'SHOW_OTHER_COMPONENT': {
            return {
                openTopBar: false
            }
        }
        default:
            return state;
    }
}