import React from "react";
import { useHistory } from "react-router-dom";
import EventTracker from "../../../utils/EventTracker";
import LocalizeText from "../../common/LocalizeText/LocalizeText";

const ConfirmMCIScreen = (props) => {
  const history = useHistory();
  const params = new URLSearchParams(window.location.search);
  const token = params.get("aid");
  const handleClick = () => {
    (async () => {
      EventTracker.instance.trackEvent("MCI Report View", {
        screen: "post_test",
      });
    })();
    history.push({ pathname: "/reportv1", search: "aid=" + token });
  };
  return (
    <div className="confirm-screen-box">
      <div className="d-lg-flex align-items-center">
        <img
          src={`${props.imgPath}`}
          className={`${props.applyClass ? "change-img-size" : ""}`}
          alt=""
        />
        <div>
          <p className="main-heading">
            <LocalizeText text={props.heading} />
          </p>
          <p className="secondary-heading">
            <LocalizeText text={props.subHeading} />
          </p>
          {props.isReportBtnShow && (
            <button
              type="button"
              onClick={handleClick}
              className="btn float-right btn-see-report"
            >
              <LocalizeText text="See Report" />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ConfirmMCIScreen;
