import React, { useEffect, useRef, useState } from "react";
import domtoimage from "dom-to-image";
import { saveAs } from "file-saver";
import Brain from "./Brain";
import DialogueCard from "./DialogueCard";
import "./BrainSelector.css";
import { isMobile } from "../../../../utils/_helper";
import LocalizeText from "../../../common/LocalizeText/LocalizeText";

const BrainSelector = ({
  data: { screenshot = false, heading = "", cards = [] },
}) => {
  const [index, setIndex] = useState(0);

  const dialogueRef = useRef(null);

  const takeScreenShot = () => {
    domtoimage
      .toJpeg(document.getElementById("behavioral-factors"), {
        quality: 1,
        bgcolor: "#fff",
      })
      .then(function (dataUrl) {
        var link = document.createElement("a");
        link.download = "behavioral-factors.jpeg";
        link.href = dataUrl;
        link.click();
      });
  };

  const checkUserDevice = isMobile() && (
    <div className="screenshot float-right">
      <button onClick={takeScreenShot}>
        <img src="/img/share-btn.svg" alt="" />
      </button>
    </div>
  );

  useEffect(() => {
    if (dialogueRef.current !== null && isMobile()) {
      dialogueRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [index]);

  const update = (selectedNewIndex) => {
    setIndex(selectedNewIndex);
  };

  return (
    <>
      {screenshot && (
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          {checkUserDevice}
        </div>
      )}

      <div
        className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
        id="behavioral-factors"
      >
        <div className="brain-selector">
          <h2>
            <LocalizeText text={heading} />
          </h2>
        </div>
        <div className="brain-bottom">
          <div className="d-grid align-items-center flex-xl-nowrap flex-lg-nowrap flex-md-wrap flex-sm-wrap flex-wrap grid-new-col-2 position-relative justify-content-between">
            <DialogueCard
              refer={dialogueRef}
              card={cards[index]}
              next={() => setIndex(index === cards.length - 1 ? 0 : index + 1)}
              prev={() => setIndex(index === 0 ? cards.length - 1 : index - 1)}
            />
            <div>
              <Brain index={index} update={update} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BrainSelector;
