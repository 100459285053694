import React, { useEffect, useState } from "react";
import domtoimage from "dom-to-image";
import { PolarArea } from "react-chartjs-2";
import { saveAs } from "file-saver";
import HealthyPointer from "./HealthyPointer";

import { hexToRgbA, isMobile } from "../../../../utils/_helper";
import "./ScorePage.css";
import LocalizeText from "../../../common/LocalizeText/LocalizeText";
import { translateText } from "../../../../../../utils/_helper";

const ScorePage = ({ data }) => {
  const sections = data.sections;
  const matrix = data.sections[1].matrix;
  const bet = Object.keys(sections[1].matrix);
  const dataSet = {
    labels: ["Behaviour ", "Thought ", "Emotion "],
    datasets: [
      {
        data: [
          matrix.BEHAVIOUR.score,
          matrix.THOUGHT.score,
          matrix.EMOTION.score,
        ],
        backgroundColor: [
          hexToRgbA(matrix.BEHAVIOUR.color, 0.75),
          hexToRgbA(matrix.THOUGHT.color, 0.75),
          hexToRgbA(matrix.EMOTION.color, 0.75),
        ],
        borderWidth: 1,
        hoverBackgroundColor: [
          hexToRgbA(matrix.BEHAVIOUR.color, 0.75),
          hexToRgbA(matrix.THOUGHT.color, 0.75),
          hexToRgbA(matrix.EMOTION.color, 0.75),
        ],
      },
    ],
  };
  const options = {
    responsive: true,
    scale: {
      ticks: {
        beginAtZero: true,
        max: 100,
        stepSize: 10,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const [bodyText, setBodyText] = useState("");

  useEffect(async () => {
    if (sections[0].body) {
      const translatedBody = await translateText(sections[0].body).catch(
        (err) => sections[0].body
      );
      setBodyText(translatedBody);
    }
  }, [sections[0].body]);

  const takeScreenShot = () => {
    domtoimage
      .toJpeg(document.getElementById("score-sheet"), {
        quality: 1,
        bgcolor: "#fff",
      })
      .then(function (dataUrl) {
        var link = document.createElement("a");
        link.download = "score-sheet.jpeg";
        link.href = dataUrl;
        link.click();
      });
  };

  const checkUserDevice = isMobile() && (
    <div className="screenshot float-right">
      <button onClick={takeScreenShot}>
        <img src="/img/share-btn.svg" alt="" />
      </button>
    </div>
  );
  return (
    <>
      {data.screenshot && (
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          {checkUserDevice}
        </div>
      )}
      <div className="score-page">
        <div className="score-page-section" id="score-sheet">
          <div className="mci-score-details">
            <div className="mci-score-graph">
              <div
                className="semi-donut-model"
                style={{
                  "--percentage": `${sections[0].score}`,
                  "--fill": "#478BA7",
                }}
              >
                <div className="user-score">
                  <p>{sections[0].score}</p>
                </div>
              </div>
              <img src="/img/score-platform.svg" alt="" />
            </div>
            <div className="mci-score-combination">
              <h2>
                <LocalizeText text={sections[0].heading} />
              </h2>
              <p dangerouslySetInnerHTML={{ __html: bodyText }}></p>
            </div>
          </div>

          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="d-flex flex-xl-nowrap flex-lg-nowrap flex-md-wrap flex-sm-wrap  flex-wrap justify-content-between">
              <div className="mci-score-contribution">
                <h2>
                  <LocalizeText text={sections[1].heading} />
                </h2>
              </div>
            </div>
            <div className="mci-score-section justify-content">
              <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12 d-flex">
                <div className="score-contribution">
                  <div className="d-flex mt-4">
                    <div
                      className="color-box"
                      style={{
                        "--fill": `${matrix.BEHAVIOUR.color}`,
                      }}
                    ></div>
                    <h6 className="color-box-text">
                      {bet[0].toLowerCase()} - {matrix.BEHAVIOUR.score}%
                    </h6>
                  </div>
                  <div className="d-flex mt-4">
                    <div
                      className="color-box"
                      style={{
                        "--fill": `${matrix.THOUGHT.color}`,
                      }}
                    ></div>
                    <h6 className="color-box-text">
                      {bet[1].toLowerCase()} - {matrix.THOUGHT.score}%
                    </h6>
                  </div>
                  <div className="d-flex mt-4">
                    <div
                      className="color-box"
                      style={{
                        "--fill": `${matrix.EMOTION.color}`,
                      }}
                    ></div>
                    <h6 className="color-box-text">
                      {bet[2].toLowerCase()} - {matrix.EMOTION.score}%
                    </h6>
                  </div>
                </div>
              </div>
              <div className="score-page-polar-chart">
                <div className="score-page-chart-box">
                  <PolarArea data={dataSet} options={options} />
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <HealthyPointer data={data} />
          </div>
        </div>
      </div>
    </>
  );
};

export default ScorePage;
