import { createStore, applyMiddleware } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import * as History from 'history';
import rootReducer from './reducers';
// import { composeWithDevTools } from 'redux-devtools-extension';
export const history = History.createBrowserHistory();

const middleware = [thunk, routerMiddleware(history)];

export default createStore(
  connectRouter(history)(rootReducer),
  applyMiddleware(...middleware)
);
