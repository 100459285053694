import axios from "axios";
const baseUrl = "https://watson-api.mindpeers.co/";
const getAllCategoryApiUrl = baseUrl + "api/test/get-mci-categories";
const getAllQuestionsUrl = baseUrl + "api/test/get-mci-questions/";
const saveUserTest = baseUrl + "api/test/add-mci-user-test";
const getUserTestReportURL = baseUrl + "api/test/get-result";
const getMCIUserDetailURL = baseUrl + "api/test/get-mci-user";
const getUserLevelURL = baseUrl + "api/level/getLevelTasksBrief";
const levelUrl = "https://dashboard.mindpeers.co/";

const getAllQuestions = async token => {
  const config = {
    headers: {
      "x-access-token": `Bearer ${token}`
    }
  };
  const res = await axios.get(getAllCategoryApiUrl, config);

  const questionCategories = res.data.Data.map(category => {
    return {
      categoryid: category._id,
      categoryQuestions: category.catName,
      questions: category.count
    };
  });
  questionCategories[0]["cumlativeCount"] = questionCategories[0]["questions"];
  for (var i = 1; i < questionCategories.length; i++) {
    questionCategories[i]["cumlativeCount"] =
      questionCategories[i]["questions"] +
      questionCategories[i - 1]["cumlativeCount"];
  }
  let questions = [];
  let results = [];
  for (let i = 0; i < questionCategories.length; i++) {
    const res = await axios.get(
      `${getAllQuestionsUrl}${questionCategories[i].categoryid}?offset=1&limit=${questionCategories[i].questions}`,
      config
    );
    const questionArray = res.data.Data.questions;
    for (var j = 0; j < questionArray.length; j++) {
      const optionsArray = questionArray[j].options;
      let options = [];
      for (var k = 0; k < optionsArray.length; k++) {
        options.push({
          optionName: optionsArray[k].name,
          optionid: optionsArray[k]._id
        });
      }
      questions.push({
        question: questionArray[j].question,
        options: options
      });
      results.push({
        questionId: questionArray[j]._id,
        answer: ""
      });
    }
  }

  return { questionCategories, questions, results };
};

const formatData = (data, staticQuestionLength) => {
  let staticQuestionOption =
    staticQuestionLength === 5
      ? ["gender", "ageGroup", "role", "department", "duration"]
      : staticQuestionLength === 6
        ? ["gender", "ageGroup", "role", "department", "teamSize", "duration"]
        : [];

  const sentApiData = {};

  staticQuestionOption.map((opt, i) => {
    if (opt === "ageGroup" && i === 1) {
      if (data[1]["answer"] === "18-30 Years")
        sentApiData["ageGroup"] = { min: 18, max: 30 };
      else if (data[1]["answer"] === "31-54 Years")
        sentApiData["ageGroup"] = { min: 31, max: 54 };
      else if (data[1]["answer"] === "55 Years & above")
        sentApiData["ageGroup"] = { min: 55, max: "above" };
    } else {
      sentApiData[opt] = data[i]["answer"];
      if (staticQuestionLength === 5) {
        sentApiData["teamSize"] = "";
      }
    }
  });

  // sentApiData["pronouns"] = data[0]["answer"];
  // sentApiData['role'] = data[2]['answer']

  sentApiData["testData"] = data.slice(staticQuestionLength).map(ans => {
    return { questionId: ans.questionId, optionId: ans.answer };
  });
  return sentApiData;
};

const saveQuestionResult = async (results, token, staticQuestionLength) => {
  const config = {
    headers: {
      "x-access-token": `Bearer ${token}`
    }
  };

  const res = await axios.post(
    saveUserTest,
    formatData(results, staticQuestionLength),
    config
  );
  return res.data;
};

const getUserTestReport = async token => {
  const config = {
    headers: {
      "x-access-token": `Bearer ${token}`
    }
  };
  try {
    const res = await axios.get(getUserTestReportURL, config);
    return res.data.Data;
  } catch (error) {
    console.log("error occured");
  }
  // return ReportResponse['data'];
};

// Level API use
const getUserLevel = async token => {
  const config = {
    headers: {
      "x-access-token": `Bearer ${token}`
    }
  };
  try {
    const levelResult = await axios.post(
      getUserLevelURL,
      {
        levels: 2,
        tasks: 2
      },
      config
    );
    return levelResult.data.Data;
  } catch (error) {
    console.log(error.message);
  }
};

const getMCIUser = async token => {
  const config = {
    headers: {
      "x-access-token": `Bearer ${token}`
    }
  };
  const res = await axios.get(getMCIUserDetailURL, config);
  return res.data;
};

export {
  getAllQuestions,
  saveQuestionResult,
  getUserTestReport,
  getMCIUser,
  getUserLevel,
  levelUrl
};
