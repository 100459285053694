import React, { useRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import EventTracker from "../../../utils/EventTracker";
import ProgressBar from "react-bootstrap/ProgressBar";
import "./MCIQuestionsList.css";
import MountainProgress from "./MountainProgress";
import MountainProgressDark from "./MountainProgressDark";
import MCIService from "../../../api/services/MCIService";
import { ToastConstant } from "../../../utils/toastConstant.js";
import CustomToast from "../../common/Toast/CustomToast";
import { useDispatch } from "react-redux";
import { isMobile } from "../../../utils/_helper";
import LocalizeText from "../../../v1/src/components/common/LocalizeText/LocalizeText.jsx";

const MCIQuestionsList = (props) => {
  const loadingMessage = "Loading Next Questions ......";

  const [loaded, changeLoaded] = useState(false);
  const [loadingMsg, changeLoadingMsg] = useState(false);
  const [questionNo, changeQuestionNo] = useState(0);
  const [progressCounter, setProgressCounter] = useState(0);
  const [RadioBtnVal, changeRadioBtnVal] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [loader, setLoader] = useState(false);
  const [questionsList, setQuestionsList] = useState([]);
  const [currentTime, setCurrentTime] = useState(new Date().toISOString());
  const [currentId, setCurrentId] = useState("");
  const [mciStartTime, setMciStartTime] = useState(new Date().toISOString());
  const [open, setOpen] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const [toastData, setToastData] = useState({
    time: 0,
    severity: "",
    message: "",
  });
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      EventTracker.instance.trackEvent("MCI Started");
    })();
  }, []);

  useEffect(() => {
    if (window.location.pathname.split("/").includes("pre-screener")) {
      (async () => {
        let response = await MCIService.listMCIQuestions("PRE_SCREENER");
        if (response.success) {
          setQuestionsList(response.data.questions);
          setCurrentId(response.data.id);
          dispatch({ type: "SET_MCI_ID", payload: response.data.id });
        }
      })();
    } else {
      (async () => {
        let response = await MCIService.listMCIQuestions("MCI");
        if (response.success) {
          setQuestionsList(response.data.questions);
          setCurrentId(response.data.id);
          dispatch({ type: "SET_MCI_ID", payload: response.data.id });
        }
      })();
    }
  }, []);

  const [questionResults, setQuestionResults] = useState([]);

  const clickRadioBtn = (option) => {
    changeRadioBtnVal(option);
    const questionval = questionResults;
    questionval.push(option);
    setQuestionResults([...questionval]);
  };

  useEffect(() => {
    if (questionResults[questionNo] && questionResults[questionNo]["answer"])
      clickRadioBtn(questionResults[questionNo]["answer"]);
  }, [questionNo]);

  const handleNext = () => {
    if (questionNo === questionsList.length - 1) {
      // Mind peers - Strength Test Completed event
      window.top.postMessage(`mciV2event_mindpeers-StrengthTestCompleted`, "*");
      if (window?.flutter_inappwebview)
        window.flutter_inappwebview.callHandler("ANALYTIC_EVENT", {
          "Questionnaire Completed": "",
        });
    }
    handleClick();

    if (questionNo === questionsList.length - 1) {
      // (async () => {
      //   EventTracker.instance.trackEvent("MCI Completed");
      // })();

      setLoader(true);

      setTimeout(() => setLoader(false), 700);
    } else {
      (async () => {
        const properties = {
          "Question Name": questionsList[questionNo]["question"],
        };
        EventTracker.instance.trackEvent("MCI Question Answered", properties);
      })();
      changeQuestionNo(questionNo + 1);
      setProgressCounter(progressCounter + 1);
      setDisableBtn(false);
    }
  };

  useEffect(() => {
    if (loaded && loadingMsg === loadingMessage) {
      changeLoadingMsg("");
      handleNext();
    }
  }, [loaded]);

  const [hasBegin, setHasBegin] = useState(false);
  const animateRef = useRef(0);
  const dot = {
    sprite: null,
    track: null,

    // Initialize the dot: connect sprite and track properties with supplied SVG elements
    init: function (sprite, track) {
      this.sprite = document.getElementById(sprite);
      this.track = document.getElementById(track);
      setHasBegin(true);
    },
  };

  const anim = {
    start: function (duration) {
      this.duration = duration;
      this.tZero = Date.now();
    },
  };

  const animateBall = (sprite, p, newPrev, newNext, timestamp) => {
    let newTimestamp = new Date();
    let currentX = newNext.x;
    let diffX = (newNext.x - newPrev.x) / 10;
    let currentY = newNext.y;
    let diffY = (newNext.y - newPrev.y) / 10;
    currentX = newPrev.x + diffX;
    currentY = newPrev.y + diffY;
    sprite.setAttribute(
      "transform",
      `translate(${currentX - 15}, ${currentY})`
    );
    if (Math.abs(newTimestamp.getTime() - timestamp.getTime()) <= 2000) {
      requestAnimationFrame(() =>
        animateBall(sprite, p, { x: currentX, y: currentY }, newNext, timestamp)
      );
    }
  };

  const handleClick = () => {
    if (!hasBegin) {
      dot.init("circle", "wire");
      anim.start(2000);
    }
    let sprite = document.getElementById("circle");
    let track = document.getElementById("wire");
    const newU = animateRef.current + 1 / questionsList?.length;
    const p = track.getPointAtLength(newU * track.getTotalLength());
    const newPrev = track.getPointAtLength(
      animateRef.current * track.getTotalLength()
    );
    const newNext = track.getPointAtLength(newU * track.getTotalLength());
    const timestamp = new Date();
    animateBall(sprite, p, newPrev, newNext, timestamp);
    animateRef.current = newU;
  };

  const goToNextOption = (question, option) => {
    if (!disableBtn) {
      handleOptionSelected(question, option);
    }
  };

  const handleOptionSelected = (question, option) => {
    setCurrentTime(new Date().toISOString());
    setSelectedOption(option.id);
    // optionSelected(option);
    setDisableBtn(true);
    const questionval = questionResults;
    questionval.push({
      question: question.id,
      type: question.type,
      fieldType: question.field_type,
      option: {
        id: option.id,
        order: option.order,
        value: option.value,
      },
      start: currentTime,
      end: new Date().toISOString(),
    });
    setQuestionResults([...questionval]);
    if (questionNo === questionsList.length - 1) {
      setProgressCounter(progressCounter + 1);
      handleClick();
      setTimeout(() => submitMCI(questionval), 200);

      // Mind peers - Strength Test Completed event
      window.top.postMessage(`mciV2event_mindpeers-StrengthTestCompleted`, "*");
      if (window?.flutter_inappwebview)
        window.flutter_inappwebview.callHandler("ANALYTIC_EVENT", {
          "Questionnaire Completed": "",
        });

      return;
    } else {
      setTimeout(() => handleNext(), 200);
    }
  };

  const submitMCI = (questionResults) => {
    (async () => {
      let response = await MCIService.submitMCI(
        currentId,
        mciStartTime,
        new Date().toISOString(),
        questionResults
      );
      if (response.success) {
        (async () => {
          EventTracker.instance.trackEvent("MCI Completed");
        })();
        props.showConfirmScreen();
      } else {
        setOpen(true);
        setToastData(
          ToastConstant.DYNAMIC_FAIL(response.data.message, 3000, "error")
        );
      }
    })();
  };

  return (
    <div
      className="col"
      style={{ backgroundColor: isMobile() && `var(--body-dark)` }}
    >
      <div className="mci-container">
        <div className="mci-questions-wrapper">
          {/* Questions Screens Related to Tabs */}
          <ProgressBar now={(100 / questionsList?.length) * progressCounter} />
          <div className="questions-wrapper">
            <div className="mci-questions-container">
              <LocalizeText text={questionsList[questionNo]?.question} />
            </div>
            <div className={`mci-questions-listing`}>
              {questionsList[questionNo]?.options.map((option) => (
                <div
                  key={option.id}
                  onClick={() => {
                    goToNextOption(questionsList[questionNo], option);
                  }}
                  className={`mci-next-question mci-questions-btn ${
                    selectedOption === option.id ? " selected" : ""
                  }`}
                >
                  <LocalizeText text={option.name} />
                </div>
              ))}
            </div>
          </div>
          <div className="mountain-image-container">
            {isMobile() ? <MountainProgressDark /> : <MountainProgress />}
          </div>
        </div>
      </div>
      {open && toastData.severity && toastData.time && (
        <CustomToast
          time={toastData.time}
          severity={toastData.severity}
          open={open}
          setOpen={setOpen}
          message={toastData.message}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  token: state.SetToken,
});

export default connect(mapStateToProps)(MCIQuestionsList);
